import { Modal, Typography, Button, Box, Stack, Divider, useTheme } from "@mui/material";
import ReportIcon from '@mui/icons-material/Report';

interface ConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string; // Changed type to React.ReactNode
    content: React.ReactNode; // Changed type to React.ReactNode
    confirmText: string;
    cancelText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    content,
    confirmText,
    cancelText,
}) => {

    const theme = useTheme()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
                maxWidth: '400px',
            }}>
                <Stack spacing={2}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <ReportIcon sx={{ color: theme.palette.warning.light }} fontSize="large" />
                        <Typography variant="h6">{title}</Typography>
                    </Stack>
                    <Divider />
                    <div>{content}</div>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={onClose} color="primary" sx={{ mr: 2 }}>{cancelText}</Button>
                        <Button variant="outlined" onClick={onConfirm} color="error">{confirmText}</Button>
                    </Box>
                </Stack>
                {/* Use Typography component for title and message */}

            </Box>
        </Modal>
    );
};

export default ConfirmationModal;