import { Grid, Typography, Stack, Container, CircularProgress, useMediaQuery, useTheme, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ProductData } from "../../../models/product";
import ProductService from "../../../api/productService";
import apiConfigInstance from "../../../api/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
//@ts-ignore
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ProductDisplay from "./productDisplay";
import ShopIcon from '@mui/icons-material/Shop';
import { AxiosError } from "axios";
import { useError } from "../../errors/ErrorContext";

export default function ProductPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const productService = new ProductService(apiConfigInstance);
    const { productId } = useParams<{ productId: string }>();
    const [product, setProduct] = useState<ProductData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { handleError } = useError();  // Use the error handling context

    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (productId) {
                    const productData = await productService.getProduct(productId);
                    setProduct(productData);
                }
            } catch (error) {
                handleError(error as AxiosError, false, "No se ha podido cargar el producto. Inténtalo nuevamente.");  // Pass custom message in Spanish
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [productId]);

    return (
        <Container>
            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                    <Stack alignItems="center" spacing={2}>
                        <Typography variant="h6">Cargando Producto</Typography>
                        <CircularProgress />
                    </Stack>
                </Grid>
            ) : !product ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                    <Stack alignItems="center" spacing={2}>
                        <ErrorOutlineIcon fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                        <Typography variant={isMobile ? "body1" : "h6"}>No se ha podido cargar el producto</Typography>
                        <Button variant="outlined" sx={{ backgroundColor: "white", opacity: 0.9 }} onClick={() => {
                            navigate("/shop")
                        }}><Stack alignItems={"center"} spacing={1} direction={"row"}>
                                <ShopIcon />
                                <Typography>Volver a la tienda</Typography>
                            </Stack></Button>
                    </Stack>
                </Grid>
            ) : (
                <ProductDisplay product={product} />
            )}
        </Container>
    );
}
