import React, { useEffect, useState } from "react";
import { ProductData } from "../../../models/product";
import ProductImageList from "../shop/ProductList";
import apiConfigInstance from "../../../api/apiConfig";
import FeaturedProductService from "../../../api/featuredProductsService";

interface FeaturedProductsProps {
    featuredProducts: ProductData[]
}

const FeaturedProducts: React.FC<FeaturedProductsProps> = ({featuredProducts}) => {

    return <ProductImageList products={featuredProducts} fullWidthCols={4}/>;
};

export default FeaturedProducts;