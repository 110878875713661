import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Divider, Grid, Typography, Link, Stack, useTheme, Paper } from "@mui/material";

const ContactInfoPage = () => {

    const theme = useTheme()

    return (
        <Paper sx={{ p: 4, backgroundColor: "rgb(255,255,255, 0.8)" }}>
            <Grid container display="flex" justifyContent="center" spacing={3}>
                {/* Header */}
                <Grid item xs={12} container alignItems="center" justifyContent="center" spacing={1} sx={{ mb: 2 }}>
                    <Grid item><MailOutlineIcon fontSize="large" color="primary" /></Grid>
                    <Grid item><Typography variant="h4" gutterBottom>Contacto</Typography></Grid>
                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                        <Divider style={{ backgroundColor: '#ddd', width: '80%' }} />
                    </Grid>
                </Grid>
                <Grid item xs={8} alignItems={"center"} sx={{ mb: 2 }}>
                    <Typography variant='body1' textAlign={"center"}>
                        Para cualquier consulta o incidencia dispones de los siguientes canales de comunicación, te responderé lo antes posible!
                    </Typography>
                </Grid>

                {/* Contact Information */}
                <Grid item xs={12} container direction="column" alignItems="center" spacing={2}>
                    <Stack alignItems={"left"} spacing={2}>
                        <Stack alignItems="center" spacing={1} direction={"row"}>
                            <Link href="mailto:nayadelife@gmail.com" color="inherit" underline="none">
                                <AlternateEmailIcon color="primary" fontSize='large' />
                            </Link>
                            <Typography fontWeight={"bold"}>Email: </Typography>
                            <Link href="mailto:nayadelife@gmail.com" variant="body1" underline="none" sx={{ color: theme.palette.primary.dark }}>
                                nayadelife@gmail.com
                            </Link>
                        </Stack>

                        <Stack alignItems="center" spacing={1} direction={"row"}>
                            <Link href="https://www.instagram.com/nayadelife" underline="none" target="_blank">
                                <InstagramIcon color="primary" fontSize='large' />
                            </Link>
                            <Typography fontWeight={"bold"}>Insta: </Typography>
                            <Link href="https://www.instagram.com/nayadelife" variant="body1" underline="none" target="_blank" sx={{ color: theme.palette.primary.dark }}>
                                ~☀️NayadeLife🌙~
                            </Link>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ContactInfoPage;
