import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, TextField, Button, Typography, Stack, Paper, Autocomplete } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CustomerDataForm } from "../../../models/order";

interface CustomerDataFormProps {
    onSubmit: (formData: CustomerDataForm) => void;
    initialvalue: CustomerDataForm | null;
}

interface ValidationErrors {
    [key: string]: {
        state: boolean;
        errorText: string;
    };
}

const provinces = [
    "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Ávila", "Badajoz", "Baleares", "Barcelona", "Burgos",
    "Cáceres", "Cádiz", "Cantabria", "Castellón", "Ciudad Real", "Córdoba", "Cuenca", "Girona", "Granada", "Guadalajara",
    "Guipúzcoa", "Huelva", "Huesca", "Jaén", "La Rioja", "Las Palmas", "León", "Lleida", "Lugo", "Madrid", "Málaga",
    "Murcia", "Navarra", "Ourense", "Palencia", "Pontevedra", "Salamanca", "Santa Cruz de Tenerife", "Segovia", "Sevilla",
    "Soria", "Tarragona", "Teruel", "Toledo", "Valencia", "Valladolid", "Vizcaya", "Zamora", "Zaragoza"
];

const CustomerDataFormComponent: React.FC<CustomerDataFormProps> = ({ onSubmit, initialvalue }) => {
    const [formData, setFormData] = useState<CustomerDataForm>({
        name: "",
        last_name: "",
        mail: "",
        phone: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        province: "",
        postal_code: ""
    });

    const [errors, setErrors] = useState<ValidationErrors>({
        "name": { state: true, errorText: "" },
        "last_name": { state: true, errorText: "" },
        "mail": { state: true, errorText: "" },
        "phone": { state: true, errorText: "" },
        "address_line_1": { state: true, errorText: "" },
        "city": { state: true, errorText: "" },
        "province": { state: true, errorText: "" },
        "postal_code": { state: true, errorText: "" }
    });

    useEffect(() => {
        if (initialvalue !== null) {
            setFormData(initialvalue);
            validateForm(initialvalue);
        }
    }, [initialvalue]);

    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        validateField(name, value);
    };

    const handleAutocompleteChange = (
        event: React.ChangeEvent<{}>,
        value: string | null
    ) => {
        const name = 'province';
        const newValue = value || '';
        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }));
        validateField(name, newValue);
    };

    const validateField = (name: string, value: string) => {
        const newErrors = { ...errors };
        let isValid = true;

        switch (name) {
            case "mail":
                const mailRegex = /^\S+@\S+\.\S+$/;
                isValid = mailRegex.test(value);
                break;
            case "address_line_1":
                isValid = value !== null && value !== ""
                break;
            case "phone":
                const phoneRegex = /^(\+?\d{1,3})?(\d{9})$/;
                isValid = phoneRegex.test(value);
                break;
            case "postal_code":
                isValid = value.trim() !== "" && !isNaN(Number(value)) && value.length >= 5 && value.length <= 8;
                break;
            case "city":
            case "province":
                const textRegex = /^[a-zA-Z\sáéíóúÁÉÍÓÚñÑ]+$/;
                isValid = textRegex.test(value.trim());
                break;
            default:
                isValid = value.trim() !== "";
        }

        newErrors[name] = {
            state: isValid,
            errorText: isValid ? "" : getErrorMessage(name)
        };

        setErrors(newErrors);
    };

    const getErrorMessage = (fieldName: string) => {
        switch (fieldName) {
            case "mail":
                return "Por favor, ingrese un correo electrónico válido";
            case "phone":
                return "Por favor, ingrese un número de teléfono válido (9 dígitos, con o sin prefijo +) ej: 612345678, +34612345678";
            case "postal_code":
                return "Por favor, ingrese un código postal válido";
            case "address_line_1":
            case "city":
            case "province":
                return "Este campo debe contener solo texto";
            default:
                return "Este campo es obligatorio";
        }
    };

    const validateForm = (form: CustomerDataForm): boolean => {
        const newErrors = { ...errors };
        let isValid = true;

        Object.keys(form).forEach((key) => {
            const value = form[key as keyof CustomerDataForm];
            let fieldIsValid = true;

            switch (key) {
                case "mail":
                    const mailRegex = /^\S+@\S+\.\S+$/;
                    fieldIsValid = mailRegex.test(value);
                    break;
                case "phone":
                    const phoneRegex = /^(\+?\d{1,3})?(\d{9})$/;
                    fieldIsValid = phoneRegex.test(value);

                    break;
                case "postal_code":
                    fieldIsValid = value.trim() !== "" && !isNaN(Number(value)) && value.length >= 5 && value.length <= 8;

                    break;
                case "address_line_1":
                    fieldIsValid = value !== null && value !== ""

                    break;
                case "city":
                case "province":
                    const textRegex = /^[a-zA-Z\sáéíóúÁÉÍÓÚñÑ]+$/;
                    fieldIsValid = textRegex.test(value.trim());
                    break;
            }

            newErrors[key] = {
                state: fieldIsValid,
                errorText: fieldIsValid ? "" : getErrorMessage(key)
            };
            
            if (!fieldIsValid) {
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = () => {
        const isValid = validateForm({ ...formData });
        console.log(isValid)
        if (isValid) {
            onSubmit(formData);
        }
    };

    return (
        <Paper sx={{ opacity: 0.8, p: 2, boxShadow: 4, marginBottom: 4 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Nombre"
                        name="name"
                        placeholder="Ingrese su nombre"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        error={!errors["name"].state}
                        helperText={errors["name"].errorText}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Apellido"
                        name="last_name"
                        placeholder="Ingrese su apellido"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                        error={!errors["last_name"].state}
                        helperText={errors["last_name"].errorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Correo Electrónico"
                        name="mail"
                        placeholder="ejemplo@correo.com"
                        type="email"
                        value={formData.mail}
                        onChange={handleInputChange}
                        required
                        error={!errors["mail"].state}
                        helperText={errors["mail"].errorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Teléfono"
                        name="phone"
                        placeholder="Ingrese su número de teléfono"
                        type="tel"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                        error={!errors["phone"].state}
                        helperText={errors["phone"].errorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Dirección 1"
                        name="address_line_1"
                        placeholder="Calle y número de casa"
                        value={formData.address_line_1}
                        onChange={handleInputChange}
                        required
                        error={!errors["address_line_1"].state}
                        helperText={errors["address_line_1"].errorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Dirección 2"
                        name="address_line_2"
                        placeholder="Apartamento, suite, etc. (opcional)"
                        value={formData.address_line_2}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Código Postal"
                        name="postal_code"
                        placeholder="Ingrese su código postal"
                        type="number"
                        value={formData.postal_code}
                        onChange={handleInputChange}
                        required
                        error={!errors["postal_code"].state}
                        helperText={errors["postal_code"].errorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Ciudad"
                        name="city"
                        placeholder="Ingrese su ciudad"
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                        error={!errors["city"].state}
                        helperText={errors["city"].errorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={provinces}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Provincia"
                                placeholder="Seleccione su provincia"
                                required
                                error={!errors["province"].state}
                                helperText={errors["province"].errorText}
                            />
                        )}
                        value={formData.province}
                        onChange={handleAutocompleteChange}
                        fullWidth
                    />
                </Grid>
                <Grid textAlign={"center"} item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        <Stack direction={"row"} spacing={1}>
                            <Typography>Continuar</Typography>
                            <ArrowForwardIosIcon />
                        </Stack>
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CustomerDataFormComponent;
