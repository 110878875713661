import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../../../style/css/zoomEffect.css'; // Import the CSS file for the zoom effect
import { Category } from '../../../models/category';


interface CategoryListProps {
  categories: Category[];
}

const CategoryList: React.FC<CategoryListProps> = ({ categories }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects if it's a mobile device
  const navigate = useNavigate();

  const handleCategoryClick = (id: number) => {
    navigate(`/shop?categoryId=${id}`);
  };

  return (

      <Stack 
        direction={isMobile ? 'column' : 'row'} 
        spacing={2} 
        justifyContent="center" 
        alignItems="center" 
        flexWrap="wrap"
      >
        {categories.map((category) => (
          <Button
            key={category.id}
            component="a"
            href={`/shop?categoryId=${category.id}`}
            variant="outlined"
            color="primary"
            sx={{
              p: 2,
              fontSize: '18px',
              textTransform: 'none',
              minWidth: 200,
              borderRadius: '64px',
              color: theme.palette.primary.dark,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            {category.name}
          </Button>
        ))}
      </Stack>

  );
}

export default CategoryList;

