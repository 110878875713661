import React, { useState } from 'react';
import { useMediaQuery, Grid, Typography, Button, ImageList, ImageListItem, Divider, Snackbar, Box } from "@mui/material";
import { Container, Stack } from "@mui/system";
import theme from "../../../style/theme";
import { ProductData } from "../../../models/product";
import ImageZoom from "./zoomImageCustom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useShoppingCartContext } from "../../context/cartContext";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

interface ProductDisplayProps {
    product: ProductData;
}

interface QuiltedImageListProps {
    cols: number;
    rowHeight?: number;
    mainImageSrc: string;
    secondaryImagesSrc: string[];
}

const QuiltedImageList: React.FC<QuiltedImageListProps> = ({ cols, mainImageSrc, secondaryImagesSrc }) => {
    const [imagesPositions, setImagesPositions] = useState({ mainImage: mainImageSrc, secondaryImages: secondaryImagesSrc });

    const handleSecondaryImageClick = (index: number) => {
        setImagesPositions(prevState => {
            const newSecondaryImages = [...prevState.secondaryImages];
            const currentMainImage = prevState.mainImage;

            // Swap the main image with the clicked secondary image
            [newSecondaryImages[index], prevState.mainImage] = [currentMainImage, newSecondaryImages[index]];

            // Return the updated state
            return { mainImage: prevState.mainImage, secondaryImages: newSecondaryImages };
        });
    };

    return (
        <ImageList variant="standard" cols={cols}>
            <ImageListItem key={mainImageSrc} cols={cols} sx={{ mb: 2 }}>
                <ImageZoom
                    src={imagesPositions.mainImage}
                    onClick={() => { }}
                    id={undefined} // Pass appropriate values if needed
                    className={undefined} // Pass appropriate values if needed
                    onError={undefined} // Pass appropriate values if needed
                    circular={false}
                />
            </ImageListItem>
            {imagesPositions.secondaryImages.map((src, index) => (
                <ImageListItem key={src} cols={1} rows={1}>
                    <img
                        src={`${src}`}
                        alt={"index"}
                        loading="lazy"
                        onClick={() => handleSecondaryImageClick(index)}
                        style={{
                            width: 160,
                            height: 160,
                            objectFit: "cover",
                            cursor: "pointer"
                        }}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const ProductDisplay: React.FC<ProductDisplayProps> = ({ product }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToCart, checkProductInCart } = useShoppingCartContext();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleAddToCart = () => {
        addToCart(product);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Grid container display="flex" justifyContent="center" spacing={4}>
                <Grid item xs={12} md={12}>
                    <Stack spacing={1}>
                        <Typography variant='h4' textAlign={"left"}>{product.name}</Typography>
                        <Divider sx={{ backgroundColor: '#ddd', width: isMobile ? '100%' : "95%" }} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Stack spacing={2}>
                        <QuiltedImageList cols={isMobile ? 2 : 4} mainImageSrc={product.main_image.data!} secondaryImagesSrc={product.secondary_images.map((img) => img.data!)} />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Typography variant="h5" textAlign={"justify"}>{product.short_description}</Typography>
                        <Typography variant="h5" fontWeight={"bold"} >{product.price} €</Typography>
                        <Box
                            sx={{
                                backgroundColor: product.sold ? theme.palette.error.light : theme.palette.success.light,
                                padding: "4", // Slightly larger padding for better spacing
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                                textAlign: "center",
                                borderRadius: "12px", // Slightly rounded corners
                                border: "2px solid", // Adds a border around the box
                                width: 250,
                                borderColor: product.sold ? theme.palette.error.dark : theme.palette.success.dark, // Border color adapts to status
                            }}
                        >
                            <Stack alignItems={"center"} direction={"row"} display={"flex"} justifyContent={"center"} spacing={2}>

                                <Typography
                                    fontWeight="bold"
                                    variant="h6"
                                    sx={{
                                        color: product.sold ? theme.palette.error.contrastText : theme.palette.success.contrastText,
                                    }}
                                >
                                    {product.sold ? "Agotado" : "Disponible"}
                                </Typography>
                                {
                                    product.sold ?
                                        <SentimentDissatisfiedIcon sx={{ color: theme.palette.error.contrastText }} />
                                        :
                                        <InsertEmoticonIcon sx={{ color: theme.palette.error.contrastText }} />

                                }

                            </Stack>

                        </Box>



                        <Typography variant="h6" textAlign={"justify"}>{product.main_description}</Typography>
                        {
                            !product.sold && (
                                <Button variant="outlined" onClick={handleAddToCart} disabled={checkProductInCart(product.id)} sx={{ backgroundColor: "rgb(255,255,255,0.9)" }}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Typography>Añadir al carrito</Typography>
                                        <ShoppingCartIcon />
                                    </Stack>
                                </Button>
                            )
                        }
                    </Stack>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message="Producto añadido al carrito"
            />
        </Container>
    );
}

export default ProductDisplay;
