import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../../../style/css/zoomEffect.css'; // Import the CSS file for the zoom effect
import { ProductData } from '../../../models/product';
import ImageZoom from '../product/zoomImageCustom';


interface ProductImageListProps {
  products: ProductData[];
  mobileCols?: number; // Make these props optional
  fullWidthCols?: number;
}

const ProductImageList: React.FC<ProductImageListProps> = ({ products, mobileCols = 2, fullWidthCols = 3 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects if it's a mobile device

  const [imgHeight, setImgHeight] = useState("300");

  useEffect(() => {
    // Only update window width after the component mounts
    const handleResize = () => {
      computeImageWidth(window.innerWidth)
    };

    // Trigger the update immediately on mount
    handleResize();

    // Add event listener for window resizing
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const computeImageWidth = (windowWidth: number) => {
    if (windowWidth < 600) {
      if (windowWidth < 400) {
        setImgHeight("180")
      } else {
        setImgHeight("240")
      }
    } else {
      setImgHeight("280")
    }
  }
  

  return (
    <ImageList cols={isMobile ? mobileCols : fullWidthCols} gap={25} sx={{ maxWidth: '100%' }}>
      {products.map((item) => (
        <ImageListItem key={item.id} sx={{ pl: 0.2, pr: 0.2, overflow: 'hidden' }}>
          <a href={`/product/${item.id}`} rel="noopener noreferrer">
            {/* Fix the image height and prevent it from growing with text */}
            <ImageZoom
              src={item.main_image.data!}
              id={undefined}
              className={undefined}
              onError={undefined}
              onClick={undefined}
              height={imgHeight}
              width={"auto"}
            />
          </a>
          <ImageListItemBar
            title={
              <Stack spacing={1} sx={{ maxWidth: "100%", mt: 1, mb: 0.5, whiteSpace: 'normal' }}>
                <Typography
                  textAlign="justify"
                  fontSize={isMobile ? "1.1rem" : "1.25rem"}  // Use a larger variant for larger screens
                  fontWeight="medium" // Use medium font weight for subtle emphasis
                  sx={{ wordBreak: 'break-word' }} // Ensures long words wrap properly
                >
                  {item.name}
                </Typography>
              </Stack>
            }
            subtitle={
              <Typography
                fontStyle="italic"
                variant="body2" // Slightly smaller font size for the price
              >
                {item.price} €
              </Typography>
            }
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

ProductImageList.defaultProps = {
  mobileCols: 2,
  fullWidthCols: 4,
};

export default ProductImageList;
