import React, { useState, ChangeEvent } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import CarouselImageService from '../../../../api/carouselService';
import apiConfigInstance from '../../../../api/apiConfig';
import { CarouselImageInput, CarouselImageOutput } from '../../../../models/carousel'
import { useAdminContext } from '../AdminContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CarouselImageEditor: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const { carouselImagesState, setCarouselImagesState } = useAdminContext()


  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const carouselImages: CarouselImageInput[] = files.map(file => ({ file }));
      try {
        // Set loading state to true while uploading
        setCarouselImagesState(prevData => ({
          ...prevData,
          loading: true,
        }));

        const carouselImageService = new CarouselImageService(apiConfigInstance);
        const uploadedImages = await carouselImageService.createCarouselImage(carouselImages);

        // Update state with the new images
        setCarouselImagesState(prevData => ({
          ...prevData,
          images: [...prevData.images, ...uploadedImages],
          loading: false,
        }));
      } catch (error) {
        console.error('Error uploading carousel images:', error);
        // Set loading state to false in case of an error
        setCarouselImagesState(prevData => ({
          ...prevData,
          loading: false,
        }));
      }
    }
  };

  const handleImageDelete = async () => {
    const currentImage = carouselImagesState.images[currentImageIndex];

    try {
      setCarouselImagesState(prevData => ({
        ...prevData,
        loading: true,
      }));

      const carouselImageService = new CarouselImageService(apiConfigInstance);
      await carouselImageService.deleteCarouselImage(currentImage.id);

      const newImages = carouselImagesState.images.filter((_, i) => i !== currentImageIndex);
      setCarouselImagesState(prevData => ({
        ...prevData,
        images: newImages,
        loading: false,
      }));

      setCurrentImageIndex(0);
    } catch (error) {
      console.error('Error deleting carousel image:', error);
      setCarouselImagesState(prevData => ({
        ...prevData,
        loading: false,
      }));
    }
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    // Create a copy of the images array to perform the reordering
    const reorderedImages = Array.from(carouselImagesState.images);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    // Update the UI state optimistically
    setCarouselImagesState(prevData => ({
      ...prevData,
      images: reorderedImages,
    }));

    // Determine the new order for the moved image
    const newOrder = result.destination.index;

    try {
      const carouselImageService = new CarouselImageService(apiConfigInstance);
      // Update the backend only for the moved image with its new order
      await carouselImageService.updateCarouselImageOrder(movedImage.id, newOrder);
    } catch (error) {
      console.error('Error updating carousel image order:', error);
      // Optionally, revert to the previous state if the update fails
      setCarouselImagesState(prevData => ({
        ...prevData,
        images: carouselImagesState.images, // revert to the previous state
      }));
    }
  };

  if (carouselImagesState.error) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
        <Typography fontSize={"30px"} gutterBottom>Carousel</Typography>
        <Paper sx={{ backgroundColor: `rgb(255,255,255,0.8)` }}>
          <Stack spacing={2} alignItems="center" sx={{ textAlign: 'center', p: 4 }}>

            <ErrorOutlineIcon fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
            <Typography variant="h6">No se han podido cargar los datos del carusel de imágenes.</Typography>
          </Stack>
        </Paper>
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
      <Typography fontSize={"30px"} gutterBottom>Carousel</Typography>

      <Box sx={{ position: 'relative', mt: 4 }}>
        {carouselImagesState.images.length > 0 && (
          <img
            src={carouselImagesState.images[currentImageIndex].data}
            alt={`carousel-${currentImageIndex}`}
            style={{ width: '100%', maxHeight: '600px' }}
          />
        )}
        {carouselImagesState.loading && (
          <Backdrop sx={{ color: '#fff', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 2 }}>
        <IconButton onClick={() => setCurrentImageIndex((currentImageIndex - 1 + carouselImagesState.images.length) % carouselImagesState.images.length)} disabled={carouselImagesState.images.length === 0}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box sx={{ display: 'flex', overflowX: 'scroll', maxWidth: '80%' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ display: 'flex', gap: '10px' }}
                >
                  {carouselImagesState.images.map((image, index) => (
                    <Draggable key={index} draggableId={`image-${index}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            minWidth: '100px',
                            cursor: 'pointer',
                            border: currentImageIndex === index ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent',
                            ...provided.draggableProps.style
                          }}
                          onClick={() => setCurrentImageIndex(index)}
                        >
                          <img src={image.data} alt={`thumbnail-${index}`} style={{ width: '100px', height: 'auto' }} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        <IconButton onClick={() => setCurrentImageIndex((currentImageIndex + 1) % carouselImagesState.images.length)} disabled={carouselImagesState.images.length === 0}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Stack>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 2 }}>
        <Button variant="outlined" component="label" startIcon={<UploadIcon />}>
          Subir Imagen
          <input type="file" multiple hidden onChange={handleImageUpload} />
        </Button>
        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={handleImageDelete} disabled={carouselImagesState.images.length === 0}>
          Eliminar
        </Button>
      </Stack>
    </Box>
  );
};

export default CarouselImageEditor;
