import { AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { UserInput, UserOutput } from "../models/user";

class UserService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    /**
     * Creates a new user.
     * @param {UserInput} data - The user data to create.
     * @returns {Promise<UserOutput>} The created user details.
     */
    async createUser(data: UserInput): Promise<UserOutput> {
        try {
            const response = await this.axiosInstance.post<UserOutput>('/users/', data);
            return response.data;
        } catch (error) {
            console.error('Error while creating user:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    /**
     * Retrieves a user by their ID.
     * @returns {Promise<UserOutput[]>} The user details.
     */
    async getUsers(): Promise<UserOutput[]> {
        try {
            const response = await this.axiosInstance.get<UserOutput[]>(`/users/`);
            return response.data;
        } catch (error) {
            console.error('Error while retrieving user by ID:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    /**
     * Retrieves a user by their ID.
     * @param {number} userId - The ID of the user to retrieve.
     * @returns {Promise<UserOutput>} The user details.
     */
    async getUserById(userId: number): Promise<UserOutput> {
        try {
            const response = await this.axiosInstance.get<UserOutput>(`/users/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Error while retrieving user by ID:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    /**
     * Retrieves a user by their username.
     * @param {string} username - The username of the user to retrieve.
     * @returns {Promise<UserOutput>} The user details.
     */
    async getUserByUsername(username: string): Promise<UserOutput> {
        try {
            const response = await this.axiosInstance.get<UserOutput>(`/users/by-username/${username}`);
            return response.data;
        } catch (error) {
            console.error('Error while retrieving user by username:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    /**
     * Updates a user's details.
     * @param {number} userId - The ID of the user to update.
     * @param {UserInput} data - The updated user data.
     * @returns {Promise<UserOutput>} The updated user details.
     */
    async updateUser(userId: number, data: UserInput): Promise<UserOutput> {
        try {
            const response = await this.axiosInstance.put<UserOutput>(`/users/${userId}`, data);
            return response.data;
        } catch (error) {
            console.error('Error while updating user:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    /**
     * Deletes a user by their ID.
     * @param {number} userId - The ID of the user to delete.
     * @returns {Promise<UserOutput>} The details of the deleted user.
     */
    async deleteUser(userId: number): Promise<UserOutput> {
        try {
            const response = await this.axiosInstance.delete<UserOutput>(`/users/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Error while deleting user:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }
}

export default UserService;
