import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Typography, Box, Stack, Grid, TablePagination, Divider, Select, MenuItem, TextField, IconButton, TableSortLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { OrderOutputFull } from '../../../../models/order';
import ClearIcon from '@mui/icons-material/Clear';

const statesMap = {
    "registered": "Registrado",
    "processing": "Pendiente de pago",
    "paid": "Pago Completado",
    "shipped": "Enviado"
}

interface OrdersTableProps {
    orders: OrderOutputFull[]
    onDeleteOrder: (orderId: string | number) => void
    onUpdateOrderStatus: (orderId: string | number, status: string) => void
}

const OrdersTable: React.FC<OrdersTableProps> = ({ orders, onDeleteOrder, onUpdateOrderStatus }) => {
    const [expandedRow, setExpandedRow] = React.useState<string | number | null>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    const handleRowClick = (orderId: string | number) => {
        setExpandedRow(prev => prev === orderId ? null : orderId);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStatusChange = (orderId: string | number, newStatus: string) => {
        onUpdateOrderStatus(orderId, newStatus);
    };

    // Memoized filtered orders based on searchTerm
    const filteredOrders = React.useMemo(() => {
        if (!searchTerm.trim()) {
            return orders; // Return all orders if no search term
        }

        const lowercasedFilter = searchTerm.toLowerCase();
        return orders.filter(order =>
            order.name.toLowerCase().includes(lowercasedFilter) ||
            order.last_name.toLowerCase().includes(lowercasedFilter) ||
            order.mail.toLowerCase().includes(lowercasedFilter)
        );
    }, [orders, searchTerm]);

    // Memoized sorted orders based on sortBy and sortOrder
    const sortedOrders = React.useMemo(() => {
        if (!sortBy) {
            return filteredOrders; // Return filtered orders if no sortBy specified
        }

        return filteredOrders.slice().sort((a, b) => {
            let aValue: any, bValue: any;

            switch (sortBy) {
                case 'name':
                case 'last_name':
                    aValue = (a[sortBy as keyof OrderOutputFull] as string).toLowerCase(); // Convert to lowercase for case-insensitive sorting
                    bValue = (b[sortBy as keyof OrderOutputFull] as string).toLowerCase();
                    break;
                case 'last_update_date':
                case 'amount':
                    aValue = a[sortBy as keyof OrderOutputFull];
                    bValue = b[sortBy as keyof OrderOutputFull];
                    break;
                default:
                    return 0; // No sorting for other columns
            }

            if (sortOrder === 'asc') {
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue);
                }
                return aValue - bValue; // This might still cause TypeScript errors for strings, but we handle numbers directly
            } else {
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return bValue.localeCompare(aValue);
                }
                return bValue - aValue; // Similar issue here, handle properly or convert to number if needed
            }
        });
    }, [filteredOrders, sortBy, sortOrder]);

    const handleSort = (column: keyof OrderOutputFull) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    return (
        <React.Fragment>
            <TextField
                label="Buscar por nombre, apellidos o email"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
            />

            <TableContainer component={Paper} elevation={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell onClick={() => handleSort('name')}>
                                <TableSortLabel active={sortBy === 'name'}
                                    direction={sortBy === 'name' ? sortOrder : 'asc'}
                                    onClick={() => handleSort('name')}>
                                    <strong>Nombre</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell >
                                <TableSortLabel active={sortBy === 'last_name'}
                                    direction={sortBy === 'last_name' ? sortOrder : 'asc'}
                                    onClick={() => handleSort('last_name')}>
                                    <strong>Apellidos</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <strong>Email</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Dirección</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Envío</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Estado</strong>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'amount'}
                                    direction={sortBy === 'amount' ? sortOrder : 'asc'}
                                    onClick={() => handleSort('amount')}>
                                    <strong>Precio</strong>
                                </TableSortLabel>

                            </TableCell>
                            <TableCell onClick={() => handleSort('last_update_date')}>
                                <TableSortLabel active={sortBy === 'last_update_date'}
                                    direction={sortBy === 'last_update_date' ? sortOrder : 'asc'}
                                    onClick={() => handleSort('last_update_date')}>
                                    <strong>Fecha</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
                            <React.Fragment key={order.id}>
                                <TableRow onClick={() => handleRowClick(order.id)} style={{ background: expandedRow === order.id ? "#f0f0f0" : "transparent" }}>
                                    <TableCell>
                                        <IconButton aria-label="expand row" size="small" onClick={(event) => {
                                            event.stopPropagation()
                                            handleRowClick(order.id)
                                        }}>
                                            {expandedRow === order.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{order.name}</TableCell>
                                    <TableCell>{order.last_name}</TableCell>
                                    <TableCell>{order.mail}</TableCell>
                                    <TableCell>{order.address}</TableCell>
                                    <TableCell>{order.shipment.name}</TableCell>
                                    <TableCell>
                                        <Select
                                            value={order.status}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                handleStatusChange(order.id, e.target.value)
                                            }}
                                            sx={{ minWidth: 100 }}
                                        >
                                            {Object.entries(statesMap).map(([key, label]) => (
                                                <MenuItem key={key} value={key}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell>{order.amount} €</TableCell>
                                    <TableCell>
                                        {new Date(order.last_update_date).toLocaleString('es-ES', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation()
                                            onDeleteOrder(order.id)
                                        }}>
                                            <ClearIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                                        <Collapse in={expandedRow === order.id} timeout="auto" unmountOnExit>
                                            <Grid container margin={1}>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ mt: 2, mb: 1 }}>Productos</Typography>
                                                    <Divider sx={{ maxWidth: "80%", mb: 1 }} />
                                                    {order.products.map((product) => (
                                                        <Stack spacing={2} direction={"row"} alignItems={"center"} key={product.id}>
                                                            <img src={product.main_image.data} style={{ width: 48, height: 48, borderRadius: 5 }} alt="Product" />
                                                            <Typography variant='body2'>{product.name}</Typography>
                                                            <Typography variant='body2'>{product.price} €</Typography>
                                                        </Stack>
                                                    ))}
                                                </Grid>
                                                <Grid item xs={6} sx={{ mb: 2 }}>
                                                    <Typography sx={{ mt: 2, mb: 1 }}>Detalles del pedido</Typography>
                                                    <Divider sx={{ maxWidth: "80%", mb: 1 }} />
                                                    <Typography variant='body2'><b>Estado</b>: {order.status}</Typography>
                                                    <Typography variant='body2'><b>Nombre</b>: {order.name}</Typography>
                                                    <Typography variant='body2'><b>Apellidos: </b>{order.last_name}</Typography>
                                                    <Typography variant='body2'><b>Dirección: </b>{order.address}</Typography>
                                                    <Typography variant='body2'><b>Email:</b> {order.mail}</Typography>
                                                    <Typography variant='body2'><b>Envío:</b> {order.shipment.name}</Typography>
                                                    <Typography variant='body2' sx={{ mt: 2 }}><b>Registrado</b>: {new Date(order.last_update_date).toLocaleString('es-ES', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}</Typography>
                                                    <Typography variant='body2'><b>Última actualización: </b>{new Date(order.last_update_date).toLocaleString('es-ES', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}</Typography>

                                                    <Typography variant='body2' sx={{ mt: 2 }}><b>Total: </b>{order.amount} €</Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                labelRowsPerPage={"Filas por página"}
                component="div"
                count={filteredOrders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
}

export default OrdersTable;
