import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AdminCategoriesPage from "./categories/AdminCategories";
import AdminShopPage from "./products/AdminShop";
import { useAdminContext } from "./AdminContext";
import SuccessModal from "../../utils/modals/SuccessModal";
import FailureModal from "../../utils/modals/FailureModal";
import AdminShipmentTypesPage from "./shipmentTypes/ShipmentTypesSection";
import OrdersSection from "./orders/OrdersSection";
import CarouselImageEditor from "./carousel/CarouselSection";
import ConfigsPage from "./configs/ConfigsPage";
import UsersPage from "./users/UsersPage";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Stack>
                    {children}
                </Stack>
            )}
        </div>
    );
};

const tabMap: { [key: string]: number } = {
    "products": 0,
    "orders": 1,
    "shipment-types": 2,
    "categories": 3,
    "carousel": 4,
    "configs": 5,
    "users": 6
};

const AdminTabs: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const { categoriesState } = useAdminContext();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const activeTab = searchParams.get('activeTab');

        if (activeTab) {
            if (tabMap.hasOwnProperty(activeTab)) {
                setSelectedTab(tabMap[activeTab]);
            }
        }
    }, [location.search]);


    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        const tabName = Object.keys(tabMap).find(key => tabMap[key] === newValue);
        if (tabName) {
            // Update the URL with the new activeTab value
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('activeTab', tabName);

            // Construct the new path with updated query parameter
            const newPath = `${location.pathname}?${searchParams.toString()}`;

            // Navigate to the new path
            navigate(newPath);
        }
    };

    return (
        <>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered={false}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ mb: 2 }}
            >
                <Tab label="Productos" />
                <Tab label="Pedidos" />
                <Tab label="Envíos" />
                <Tab label="Categorías" />
                <Tab label="Carousel" />
                <Tab label="Confgiuración" />
                <Tab label="Usuarios" />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                {categoriesState.loading ? (
                    <Stack spacing={3} display={"flex"} justifyContent={"center"} alignItems={"center"} height={"25vh"}>
                        <Typography> Cargando categorias ...</Typography>
                        <CircularProgress color="inherit" />
                    </Stack>
                ) : (
                    <AdminShopPage
                    />
                )}
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <OrdersSection />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <AdminShipmentTypesPage />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
                {categoriesState.loading ? (
                    <Stack spacing={3} display={"flex"} justifyContent={"center"} alignItems={"center"} height={"25vh"}>
                        <Typography> Cargando categorías ...</Typography>
                        <CircularProgress color="inherit" />
                    </Stack>
                ) : (
                    <AdminCategoriesPage />
                )}
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
                <CarouselImageEditor />
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
                <ConfigsPage />
            </TabPanel>
            <TabPanel value={selectedTab} index={6}>
                <UsersPage />
            </TabPanel>
        </>
    );
};

export default AdminTabs;