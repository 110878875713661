import { Button, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import AddShipmentTypeModal from "./AddShipmentTypeModal";
import ShipmentsTypeTable from "./ShipmentTypesTable";
import ShipmentTypeService from "../../../../api/shipmentTypeService";
import { ShipmentType, ShipmentTypeInput } from "../../../../models/shipmentType";
import { useAdminContext } from "../AdminContext";
import apiConfigInstance from "../../../../api/apiConfig";
import { ErrorOutline } from "@mui/icons-material";
import { useError } from "../../../errors/ErrorContext";
import { AxiosError } from "axios";


const AdminShipmentTypesPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { handleError } = useError()

    const { shipmentTypesState, setShipmentTypesState } = useAdminContext();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const shipmentService = new ShipmentTypeService(apiConfigInstance);

    const addShipment = async (newShipment: ShipmentTypeInput) => {

        try {
            const createdShipment = await shipmentService.createShipmentType(newShipment);
            setShipmentTypesState((prevState) => ({
                ...prevState,
                shipmentTypes: [...prevState.shipmentTypes, createdShipment], // Fixed property name
                loading: false
            }));
        } catch (error) {
            handleError(error as Error | AxiosError, true, "No se han podido crear el tipo de envío.");
        }
    }

    const updateShipment = async (updatedShipment: ShipmentType) => {
        try {
            // Call the shipment service to update the shipment type
            const updatedShipmentResponse = await shipmentService.updateShipmentType(updatedShipment.id, updatedShipment);

            // Find the index of the updated shipment type in the shipmentTypes array
            const shipmentIndex = shipmentTypesState.shipmentTypes.findIndex(shipment => shipment.id === updatedShipmentResponse.id);

            // Create a new array with the updated shipment type replacing the old one at the found index
            const updatedShipments = [...shipmentTypesState.shipmentTypes];
            updatedShipments[shipmentIndex] = updatedShipmentResponse;

            // Update the state with the new array of shipment types
            setShipmentTypesState((prevState) => ({
                ...prevState,
                shipmentTypes: updatedShipments,
                loading: false
            }));

            // Optionally, you can show a notification or perform any other action upon successful update
            console.log('Shipment type updated successfully');
        } catch (error) {
            // Handle any errors that occur during update
            handleError(error as Error | AxiosError, true, 'No se ha podido actualizar la categoría.');
            // Optionally, you can show an error message or perform any other action upon failure
        }
    };

    const deleteShipment = async (shipment: ShipmentType) => {
        try {
            // Call the shipment service to delete the shipment type
            await shipmentService.deleteShipmentType(shipment.id);

            // Once the shipment type is deleted successfully, update the state to remove the deleted shipment type
            setShipmentTypesState((prevState) => ({
                ...prevState,
                shipmentTypes: prevState.shipmentTypes.filter((s) => s.id !== shipment.id), // Fixed property name
                loading: false // Optionally, you can set loading to false here
            }));

        } catch (error) {
            // Handle any errors that occur during deletion
            handleError(error as Error | AxiosError, true, 'No se ha podido eliminar la categoría.');
            // Optionally, you can show an error message or perform any other action upon failure
        }
    };

    if (shipmentTypesState.error) {
        return (
            <Paper sx={{ minHeight: "50vh", textAlign: "center", alignContent: "center", backgroundColor: "rgb(255,255,255, 0.8)" }}>
                <Stack alignItems={"center"} spacing={1}>

                    <ErrorOutline fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                    <Typography variant='h6'>No se han podido cargar los envios.</Typography>
                </Stack>
            </Paper>)
    }

    return (
        <Grid container sx={{ minWidth: "40vw" }}>
            <Grid item xs={12} md={12}>
                <Stack spacing={3} sx={{ width: "100%" }}>
                    <Stack direction={"row"} alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant="h5" sx={{ pl: 2 }}>Envíos</Typography>
                        <Button variant="outlined" onClick={() => { setOpenModal(true) }}>
                            <Stack direction={"row"} alignItems="center">
                                <AddIcon />
                                {!isMobile && <Typography>Añadir tipo de envío</Typography>}
                            </Stack>
                        </Button>
                    </Stack>
                    <ShipmentsTypeTable shipments={shipmentTypesState.shipmentTypes} onShipmentUpdate={updateShipment} onShipmentDelete={deleteShipment} />
                </Stack>
            </Grid>
            <AddShipmentTypeModal open={openModal} onClose={() => { setOpenModal(false) }} onAddShipmentType={(newShipmentType) => { addShipment(newShipmentType) }} />
        </Grid>

    )
}

export default AdminShipmentTypesPage;
