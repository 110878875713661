import { AxiosInstance } from 'axios';
import { Category, CategoryInput } from '../models/category';
import { ApiConfig } from './apiConfig';

class CategoryService {
  axiosInstance: AxiosInstance;

  constructor(apiConfig: ApiConfig) {
    this.axiosInstance = apiConfig.axiosInstance;
  }

  async createCategory(categoryData: CategoryInput): Promise<Category> {
    try {
      const response = await this.axiosInstance.post('/categories', categoryData);
      return response.data;
    } catch (error) {
      console.error('Error creating category:', error);
      throw error;
    }
  }

  async getCategory(categoryId: number): Promise<Category> {
    try {
      const response = await this.axiosInstance.get(`/categories/${categoryId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching category:', error);
      throw error;
    }
  }

  async getCategories(): Promise<Category[]> {
    try {
      const response = await this.axiosInstance.get(`/categories`);
      return response.data;

    } catch (error) {
      console.error('Error fetching category:', error);
      throw error;
    }
  }

  async updateCategory(categoryId: number, categoryData: Category): Promise<Category> {
    try {
      const response = await this.axiosInstance.put(`/categories/${categoryId}`, categoryData);
      return response.data;
    } catch (error) {
      console.error('Error updating category:', error);
      throw error;
    }
  }

  async deleteCategory(categoryId: number): Promise<Category> {
    try {
      const response = await this.axiosInstance.delete(`/categories/${categoryId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting category:', error);
      throw error;
    }
  }
}

export default CategoryService;