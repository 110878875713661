import React, { useState, ChangeEvent } from 'react';
import { TextField, Button, Container, Typography, Box, Grid, Paper, IconButton, Stack, CircularProgress, useTheme } from '@mui/material';
import { ErrorOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import SuccessModal from '../../../utils/modals/SuccessModal';
import FailureModal from '../../../utils/modals/FailureModal';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import { Configs } from '../../../../models/configs';
import { useAdminContext } from '../AdminContext';
import { useError } from '../../../errors/ErrorContext';
import { AxiosError } from 'axios';

type ShowPasswordState = {
    StripeConfig: {
        api_key: boolean;
        webhook_secret: boolean;
        webhook_endpoint_secret: boolean;
    };
    SecurityConfig: {
        secret_key: boolean;
        api_key: boolean;
    };
    MailConfig: {
        sender_password: boolean;
    };
};

const ConfigsPage: React.FC = () => {
    const { configsState, updateConfigs, setConfigsState } = useAdminContext(); // Get state and method from context
    const theme = useTheme();
    const { handleError } = useError()

    const [showPassword, setShowPassword] = useState<ShowPasswordState>({
        StripeConfig: {
            api_key: false,
            webhook_secret: false,
            webhook_endpoint_secret: false,
        },
        SecurityConfig: {
            secret_key: false,
            api_key: false,
        },
        MailConfig: {
            sender_password: false,
        },
    });

    const [successModalData, setSuccessModalData] = useState<{ open: boolean, text: string, title: string }>({ open: false, text: "", title: "" });

    const handleInputChange = (configType: keyof Configs, field: string, value: string | number) => {
        if (configsState.configs) {
            const updatedConfigs = {
                ...configsState.configs,
                [configType]: {
                    ...configsState.configs[configType],
                    [field]: value,
                },
            };
            const currentConfigs = configsState
            currentConfigs.configs = updatedConfigs
            setConfigsState({ ...currentConfigs })
        }
    };

    const handleTogglePasswordVisibility = <T extends keyof ShowPasswordState>(configType: T, field: keyof ShowPasswordState[T]) => {
        setShowPassword(prevState => ({
            ...prevState,
            [configType]: {
                ...prevState[configType],
                [field]: !prevState[configType][field],
            },
        }));
    };

    const handleSubmit = async () => {
        if (configsState.configs) {
            try {
                await updateConfigs(configsState.configs);
                setSuccessModalData({ open: true, title: "Configuraciones actualizadas", text: "Las configuraciones se han actualizado con éxito." });
            } catch (error) {
                handleError(error as Error | AxiosError, true, "No se han podido actualizar las configuraciones.");
            }
        }
    };

    if (configsState.loading) {
        return (<Paper sx={{ minHeight: "50vh", textAlign: "center", alignContent: "center", backgroundColor: "rgb(255,255,255, 0.8)" }}>
            <Stack alignItems={"center"} spacing={1}>
                <CircularProgress />
                <Typography variant='h6'>Cargando configuraciones...</Typography>
            </Stack>
        </Paper>)

    }

    if (configsState.error) {
        return (<Paper sx={{ minHeight: "50vh", textAlign: "center", alignContent: "center", backgroundColor: "rgb(255,255,255, 0.8)" }}>
            <Stack alignItems={"center"} spacing={1}>
                <ErrorOutline fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                <Typography variant='h6'>No se han podido cargar las configuraciones.</Typography>
            </Stack>
        </Paper>)
    }

    return (
        <Paper sx={{p:2, backgroundColor: "rgb(255,255,255,0.8)"}}>
            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom textAlign={"center"} sx={{ mb: 4 }}>
                    Editor de Configuraciones
                </Typography>

                {/* StripeConfig Form */}
                <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1, mb: 4 }}>
                        <CreditCardIcon />
                        <Typography variant="h6" gutterBottom >
                            Configuración de Stripe
                        </Typography>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Clave API"
                                fullWidth
                                type={showPassword.StripeConfig.api_key ? 'text' : 'password'}
                                value={configsState.configs?.StripeConfig.api_key || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('StripeConfig', 'api_key', e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => handleTogglePasswordVisibility('StripeConfig', 'api_key')}>
                                            {showPassword.StripeConfig.api_key ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Secreto del Webhook"
                                fullWidth
                                type={showPassword.StripeConfig.webhook_secret ? 'text' : 'password'}
                                value={configsState.configs?.StripeConfig.webhook_secret || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('StripeConfig', 'webhook_secret', e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => handleTogglePasswordVisibility('StripeConfig', 'webhook_secret')}>
                                            {showPassword.StripeConfig.webhook_secret ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="URL de Éxito"
                                fullWidth
                                value={configsState.configs?.StripeConfig.success_url || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('StripeConfig', 'success_url', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="URL de Cancelación"
                                fullWidth
                                value={configsState.configs?.StripeConfig.cancel_url || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('StripeConfig', 'cancel_url', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Moneda"
                                fullWidth
                                value={configsState.configs?.StripeConfig.currency || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('StripeConfig', 'currency', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                {/* SecurityConfig Form */}
                <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1, mb: 4 }}>
                        <LockIcon />
                        <Typography variant="h6" gutterBottom >
                            Configuración de Seguridad
                        </Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Clave Secreta"
                                fullWidth
                                type={showPassword.SecurityConfig.secret_key ? 'text' : 'password'}
                                value={configsState.configs?.SecurityConfig.secret_key || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('SecurityConfig', 'secret_key', e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => handleTogglePasswordVisibility('SecurityConfig', 'secret_key')}>
                                            {showPassword.SecurityConfig.secret_key ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Algoritmo"
                                fullWidth
                                value={configsState.configs?.SecurityConfig.algorithm || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('SecurityConfig', 'algorithm', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Tiempo de Expiración del Token"
                                type="number"
                                fullWidth
                                value={configsState.configs?.SecurityConfig.token_expire_time || 0}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('SecurityConfig', 'token_expire_time', parseInt(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Clave API"
                                fullWidth
                                type={showPassword.SecurityConfig.api_key ? 'text' : 'password'}
                                value={configsState.configs?.SecurityConfig.api_key || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('SecurityConfig', 'api_key', e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => handleTogglePasswordVisibility('SecurityConfig', 'api_key')}>
                                            {showPassword.SecurityConfig.api_key ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                {/* MailConfig Form */}
                <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1, mb: 4 }}>
                        <EmailIcon />
                        <Typography variant="h6" gutterBottom >
                            Configuración de Correo
                        </Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Correo del Remitente"
                                type="email"
                                fullWidth
                                value={configsState.configs?.MailConfig.sender_mail || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('MailConfig', 'sender_mail', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Contraseña del Remitente"
                                type={showPassword.MailConfig.sender_password ? 'text' : 'password'}
                                fullWidth
                                value={configsState.configs?.MailConfig.sender_password || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('MailConfig', 'sender_password', e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => handleTogglePasswordVisibility('MailConfig', 'sender_password')}>
                                            {showPassword.MailConfig.sender_password ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                {/* Submit Button */}
                <Box textAlign="center" mt={4}>
                    <Button variant="outlined" color="primary" onClick={handleSubmit}>
                        Guardar Configuraciones
                    </Button>
                </Box>
                <SuccessModal
                    open={successModalData.open}
                    onClose={() => setSuccessModalData({ open: false, text: "", title: "" })}
                    title={successModalData.title}
                    content={successModalData.text}
                />
            </Container>
        </Paper>
    );
};

export default ConfigsPage;
