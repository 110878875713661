import React from "react";
import { Category } from "../../../../models/category";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Box, TableContainer, Paper } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from "../../../utils/modals/ConfirmationModal";

interface CategoriesTableProps {
    categories: Category[];
    onCategoryUpdate: (category: Category) => void;
    onCategoryDelete: (category: Category) => void;
}

const CategoriesTable: React.FC<CategoriesTableProps> = ({ categories, onCategoryUpdate, onCategoryDelete }) => {
    const [editedCategories, setEditedCategories] = React.useState<Partial<Category>[]>([]);
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [categoryToDelete, setCategoryToDelete] = React.useState<Category | null>(null);

    const handleFieldChange = (index: number, field: keyof Category, value: string) => {
        const updatedCategories = [...editedCategories];
        updatedCategories[index] = {
            ...editedCategories[index],
            [field]: value
        };
        setEditedCategories(updatedCategories);
    };

    const handleBlur = (index: number) => {
        const editedCategory = editedCategories[index];
        if (editedCategory) {
            const categoryToUpdate = {
                ...categories[index],
                ...editedCategory
            };
            onCategoryUpdate(categoryToUpdate);
        }
    };

    const handleDeleteConfirmation = () => {
        if (categoryToDelete) {
            onCategoryDelete(categoryToDelete);
            setCategoryToDelete(null);
            setShowDeleteModal(false);
        }
    };

    const handleDeleteCancel = () => {
        setCategoryToDelete(null);
        setShowDeleteModal(false);
    };

    return (
        <TableContainer component={Paper} elevation={3} sx={{ marginTop: 4, padding: 2, backgroundColor: "rgb(255,255,255,0.9)"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((category, index) => (
                        <TableRow key={category.id}>
                            <TableCell>
                                <TextField
                                    type="text"
                                    value={editedCategories[index]?.name || category.name}
                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                    onBlur={() => handleBlur(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="text"
                                    fullWidth
                                    value={editedCategories[index]?.description || category.description}
                                    onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                    onBlur={() => handleBlur(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    setCategoryToDelete(category);
                                    setShowDeleteModal(true);
                                }}><CloseIcon /></IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ConfirmationModal
                open={showDeleteModal}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirmation}
                title="Eliminar Categoría"
                content="Si continuas la categoría se eliminará."
                confirmText="Eliminar"
                cancelText="Cancelar"
            />
        </TableContainer>
    );
};

export default CategoriesTable;
