import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { PaymentMethod } from '@stripe/stripe-js';


interface CardPaymentFormProps {
  onCardValidation: (paymentMethod: PaymentMethod | null) => void;
}

const CardPaymentForm: React.FC<CardPaymentFormProps> = ({ onCardValidation }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const genericErrorMessage = "No se ha podido verificar la tarjeta, por favor revise los datos introducidos e intentelo de nuevo.";

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!
    });

    setLoading(false);

    if (error) {
      if (error.message) {
        setError(error.message);
      } else {
        setError(genericErrorMessage);
      }
    } else {
      onCardValidation(paymentMethod);
    }
  };

  return (
    <Paper sx={{ minWidth: "80%", p: 4, mx: 'auto', boxShadow: 4, textAlign: 'center' }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} textAlign="left">
          <Stack spacing={1} direction="row" alignItems="center">
            <CreditCardIcon fontSize='large' />
            <Typography variant='h6'>Tarjeta de crédito (Stripe)</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#ddd' }} />
        </Grid>
        <Grid item xs={12}>
          <Box minWidth={"100%"}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '22px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {error && <Typography fontStyle="italic" sx={{ mt: 2, mb: 2 }}>{error}</Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
          >
            {loading ? 'Processing...' : 'Pagar'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardPaymentForm;
