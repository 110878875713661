import React, { ReactNode } from 'react';
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';



const Layout = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box sx={{mb: 20}}>
                <Navbar />
            </Box>

            <Container
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    mb: 10
                }}
            >
                <Outlet />
            </Container>
            <Footer />
        </Box>
    );
};

export default Layout;