import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Typography, IconButton, Divider } from "@mui/material";
import { ShipmentTypeInput } from "../../../../models/shipmentType";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

interface AddShipmentTypeModalProps {
    open: boolean;
    onClose: () => void;
    onAddShipmentType: (shipmentType: ShipmentTypeInput) => void;
}

interface FormState {
    name: string;
    description: string;
    price: number;
    errors: {
        name: boolean;
    };
    touched: {
        name: boolean;
    };
}

const initialFormState: FormState = {
    name: "",
    description: "",
    price: 0.0,
    errors: {
        name: false,
    },
    touched: {
        name: false,
    },
};

const AddShipmentTypeModal: React.FC<AddShipmentTypeModalProps> = ({ open, onClose, onAddShipmentType }) => {
    const [formState, setFormState] = useState<FormState>(initialFormState);

    const handleFieldChange = (field: keyof FormState, value: string) => {
        setFormState({
            ...formState,
            [field]: value,
            errors: {
                ...formState.errors,
                [field]: field === "name" ? !value.trim() : false,
            },
            touched: {
                ...formState.touched,
                [field]: true,
            },
        });
    };

    const handleAddShipmentType = () => {
        const { name, description, price } = formState;
        const errors = {
            name: !name.trim(),
        };
        const touched = {
            name: true,
        };

        setFormState({ ...formState, errors, touched });

        if (!errors.name) {
            onAddShipmentType({ name, description, price });
            setFormState(initialFormState);
            onClose();
        }
    };

    const handleClose = () => {
        setFormState(initialFormState);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>

            <DialogTitle>
                <Stack direction="row" alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <AddIcon fontSize="large"></AddIcon>
                        <Typography variant="body1">Añadir tipo de envío</Typography>
                    </Stack>
                    <IconButton onClick={handleClose}><CloseIcon></CloseIcon></IconButton>
                </Stack>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nombre"
                    type="text"
                    fullWidth
                    value={formState.name}
                    onChange={(e) => handleFieldChange("name", e.target.value)}
                    error={formState.errors.name}
                    helperText={formState.errors.name && "El nombre es obligatorio."}
                    required // Add the required attribute
                />
                <TextField
                    margin="dense"
                    label="Descripción"
                    multiline
                    rows={3}
                    type="text"
                    fullWidth
                    value={formState.description}
                    onChange={(e) => handleFieldChange("description", e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Precio"
                    type="number"
                    fullWidth
                    value={formState.price}
                    onChange={(e) => handleFieldChange("price", e.target.value)}
                />
            </DialogContent>
            <DialogActions  sx={{p: 2}}>
                <Button variant="outlined" color="error" onClick={handleClose}><Typography variant="body2">Cancelar</Typography></Button>
                <Button
                    onClick={handleAddShipmentType}
                    variant="outlined"
                    color="primary"
                    disabled={formState.errors.name}
                >
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <AddIcon />
                        <Typography variant="body2">Añadir</Typography>
                    </Stack>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddShipmentTypeModal;