import React, { createContext, useState, useContext, ReactNode } from 'react';
import { AxiosError } from 'axios';
import FailureModal from '../utils/modals/FailureModal';
import AxiosErrorProcessor from './AxiosErrorProcessor';


interface ErrorContextProps {
  handleError: (error: Error, showModal?: boolean, customMessage?: string, callback?: () => void) => void;
  clearError: () => void;
}

const ErrorContext = createContext<ErrorContextProps | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [customMessage, setCustomMessage] = useState<string | undefined>(undefined);
  const [callback, setCallback] = useState<() => void>()

  const handleError = (error: Error, showModal: boolean = true, customMessage?: string, callback?: () => void) => {
    setError(error);
    setShowModal(showModal);
    setCustomMessage(customMessage);
    setCallback(() => callback)
  };

  const clearError = () => {
    setError(null);
    setShowModal(false);
    setCustomMessage(undefined);
  };

  const clearWithCallback = () => {
    setError(null);
    setShowModal(false);
    setCustomMessage(undefined);

    if (callback) {
      callback()
    }
  };

  return (
    <ErrorContext.Provider value={{ handleError, clearError }}>
      {children}
      {error && (
        error instanceof AxiosError ? (
          <AxiosErrorProcessor
            error={error}
            showModal={showModal}
            customMessage={customMessage}
            callback={callback}
          />
        ) : (
          <FailureModal
            open={showModal}
            onClose={clearError}
            onClick={clearWithCallback}
            title="Ha ocurrido un error"
            content={customMessage || error.message}
          />
        )
      )}
    </ErrorContext.Provider>
  );
};

export const useError = (): ErrorContextProps => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};