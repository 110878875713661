import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material"
import { ProductData } from "../../../../models/product"
import ProductDisplay from "../../product/productDisplay"
import { Close, Visibility } from '@mui/icons-material';

interface ProductViewModalProps {
    open: boolean;
    product: ProductData;
    onClose: () => void;
}

const ProductViewModal: React.FC<ProductViewModalProps> = ({ open, product, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Visibility fontSize="large" />
                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Preview</Typography>
                </Stack>
                <IconButton onClick={onClose} aria-label="close">
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <ProductDisplay product={product} />
            </DialogContent>
        </Dialog>
    )
}

export default ProductViewModal;