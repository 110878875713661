import axios, { AxiosInstance } from 'axios';

export class ApiConfig {
  baseURL: string;
  headers: Record<string, string>;
  axiosInstance: AxiosInstance;

  constructor() {
    // Use the environment variable to set the baseURL
    this.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

    if (!this.baseURL) {
      throw new Error('API_URL environment variable is not set.');
    }

    // Set up the headers
    this.headers = {
      'Content-Type': 'application/json',
      // Add other common headers here
    };

    // Add API key to headers if available
    const apiKey = process.env.REACT_APP_API_KEY;
    if (apiKey) {
      this.headers['Authorization'] = `Bearer ${apiKey}`;
    } else {
      console.warn('API_KEY environment variable is not set.');
    }

    // Create the axios instance with the base URL and headers
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: this.headers,
      withCredentials: true // Include credentials if needed
    });
  }
}

const apiConfigInstance = new ApiConfig();

export default apiConfigInstance;