import { AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { Configs } from "../models/configs"; // Assuming this is the correct import for your configs model

class ConfigService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    /**
     * Extracts all configurations stored in the database.
     * @returns {Promise<AppDBStoredConfigs>} The configurations stored in the database.
     */
    async extractAppDBStoredConfigs(): Promise<Configs> {
        try {
            const response = await this.axiosInstance.get<Configs>('/config/in_database');
            return response.data;
        } catch (error) {
            console.error('Error while extracting app DB stored configs:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    /**
     * Updates the configurations stored in the database.
     * @param {AppDBStoredConfigs} data - The updated configuration data.
     * @returns {Promise<AppDBStoredConfigs>} The updated configurations after saving to the database.
     */
    async updateAppDBStoredConfigs(data: Configs): Promise<Configs> {
        try {
            const response = await this.axiosInstance.put<Configs>('/config/update', data);
            return response.data;
        } catch (error) {
            console.error('Error while updating app DB stored configs:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }
}

export default ConfigService;
