import { Checkbox, FormControlLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ShipmentType } from "../../../models/shipmentType";
import ShipmentTypeService from "../../../api/shipmentTypeService";
import apiConfigInstance from "../../../api/apiConfig";
import { useShoppingCartContext } from "../../context/cartContext";

interface PaymentSummaryProps {
    shipment: ShipmentType,
    amount: number
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({shipment, amount}) => {

    return (
        <TableContainer component={Paper} sx={{ mx: "auto", boxShadow: 4, marginBottom: 4 }} >
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell><Typography fontWeight={"bold"} variant="h6">Subtotal</Typography></TableCell>
                        <TableCell><Typography variant="body1">{amount} €</Typography></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Typography fontWeight={"bold"} variant="h6">Envío</Typography></TableCell>
                        <TableCell>
                            <Stack spacing={1}>
                                <FormControlLabel
                                    key={shipment.id}
                                    control={<Checkbox
                                        disabled={true}
                                        checked={true}
                                    />}
                                    label={<Typography>
                                        {shipment.name} <b>{shipment!.price} €</b>
                                    </Typography>}
                                />
                                <Typography variant="body2" sx={{ pl: 4 }}>{shipment.description} €</Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Typography fontWeight={"bold"} variant="h6">Total</Typography></TableCell>
                        <TableCell><Typography variant="body1">{amount + shipment.price} €</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PaymentSummary;