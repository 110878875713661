import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ThemeProvider from '@mui/system/ThemeProvider/ThemeProvider';
import theme from './style/theme';
import Layout from './components/core/Layout';
import HomePage from './components/pages/home/Home';
import ShopPage from './components/pages/shop/shop';
import ProductPage from './components/pages/product/productPage';
import CartPage from './components/pages/cart/CartPage';
import PaymentPage from './components/pages/payment/paymentPage';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import AdminPage from './components/pages/admin/AdminPage';
import { ShoppingCartProvider } from './components/context/cartContext';
import LoginPage from './components/pages/login/LoginPage';
import { AuthProvider } from './components/context/securityContext';
import PrivateRoute from './components/security/privateRoute';
import ContactInfoPage from './components/pages/contact/contactPage';
import CssBaseline from '@mui/material/CssBaseline';
import SuccessPage from './components/pages/payment/PaymentSuccess';

import { ErrorProvider } from './components/errors/ErrorContext';


function App() {
  return (

    <BrowserRouter>
      <ErrorProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ShoppingCartProvider>
              <Routes>
                <Route path='/' element={<Layout />}>
                  <Route path='/' element={<HomePage />}></Route>
                  <Route path='/login' element={<LoginPage />}></Route>
                  <Route path='/shop' element={<ShopPage />}></Route>
                  <Route path='/contact' element={<ContactInfoPage />}></Route>
                  <Route path='/product/:productId' element={<ProductPage />}></Route>
                  <Route path='/cart' element={<CartPage />}></Route>
                  <Route path='/payment' element={<PaymentPage />}></Route>
                  <Route path='/success' element={<SuccessPage />}></Route>
                  <Route path="/admin" element={<PrivateRoute component={AdminPage} />} />
                </Route>
              </Routes>
            </ShoppingCartProvider>
          </ThemeProvider>
        </AuthProvider>
      </ErrorProvider>
    </BrowserRouter>
  );
}

export default App;
