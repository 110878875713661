import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Navigate } from 'react-router-dom';
import { useAuth } from '../context/securityContext';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {

  const { isAuthenticated, authenticating } = useAuth();

  if (authenticating) {
    return <div>Loading...</div>; // or a spinner
  }

  if (!authenticating && isAuthenticated === false) {
    console.log(isAuthenticated)
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;