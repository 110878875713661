import { Modal, Typography, Button, IconButton, Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

interface FailureModalProps {
    open: boolean;
    onClose: () => void;
    onClick?: () => void;
    buttonContent?: React.ReactNode;
    title: React.ReactNode;
    content: React.ReactNode;
}

const FailureModal: React.FC<FailureModalProps> = ({
    open,
    onClose,
    onClick,
    buttonContent,
    title,
    content,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const onButtonClick = () => {
        if (onClick) {
            onClick()
        } else {
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="failure-modal-title"
            aria-describedby="failure-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{
                position: 'relative',  // Positioning for the close button
                bgcolor: 'background.paper',
                boxShadow: 25,
                p: 4,
                borderRadius: '12px',
                maxWidth: isMobile ? '400px' : '600px',
                width: '100%',
            }}>
                {/* Close Button */}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Stack spacing={3}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <ErrorIcon sx={{ color: theme.palette.error.main, fontSize: 40 }} />
                        <Typography variant="h5" fontWeight="bold" color="textPrimary">
                            {title}
                        </Typography>
                    </Stack>
                    <Typography variant="body1" color="textSecondary">
                        {content}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={onButtonClick}
                        sx={{
                            alignSelf: 'center',
                            bgcolor: theme.palette.error.main,
                            color: 'white',
                            '&:hover': {
                                bgcolor: theme.palette.error.dark,
                            },
                        }}
                    >
                        {
                            buttonContent ? buttonContent: "Cerrar"
                        }
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default FailureModal;
