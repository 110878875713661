import { AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { CarouselImageOutput, CarouselImageInput } from "../models/carousel";

class CarouselImageService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    async createCarouselImage(data: CarouselImageInput[]): Promise<CarouselImageOutput[]> {

        try {
            // Initialize a new FormData instance
            const formData = new FormData();
            // Iterate through the data array and append each file to the form data
            data.forEach((item) => {
                console.log(item)
                formData.append('files', item.file); // Use 'files' as the key for each file
            });

            formData.forEach((value, key) => {
                console.log(key, value);
            });
            // Send the POST request with the FormData
            const response = await this.axiosInstance.post<CarouselImageOutput[]>('/carousel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Let the browser set this automatically
                },
            });

            // Return the array of CarouselImageOutput objects
            return response.data;
        } catch (error) {
            console.error('Error while creating carousel images:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getCarouselImages(skip: number = 0, limit: number = 10): Promise<CarouselImageOutput[]> {
        try {
            const response = await this.axiosInstance.get<CarouselImageOutput[]>('/carousel', {
                params: { skip, limit },
            });

            return response.data;
        } catch (error) {
            console.error('Error while getting carousel images:', error);
            throw error;
        }
    }

    async updateCarouselImageOrder(imageId: number, newOrder: number): Promise<CarouselImageOutput> {
        try {
            const response = await this.axiosInstance.put<CarouselImageOutput>(`/carousel/${imageId}?new_order=${newOrder}`);

            return response.data;
        } catch (error) {
            console.error('Error while updating carousel image order:', error);
            throw error;
        }
    }

    async deleteCarouselImage(imageId: number): Promise<CarouselImageOutput> {
        try {
            const response = await this.axiosInstance.delete<CarouselImageOutput>(`/carousel/${imageId}`);

            return response.data;
        } catch (error) {
            console.error('Error while deleting carousel image:', error);
            throw error;
        }
    }
}

export default CarouselImageService;
