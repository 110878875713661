import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import apiConfigInstance from '../../api/apiConfig';
import SecurityService from '../../api/securityService';

interface AuthContextProps {
    isAuthenticated: boolean;
    authenticating: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [authenticating, setAuthenticating] = useState<boolean>(true); // Initially set to true to indicate checking

    const securityService = new SecurityService(apiConfigInstance);

    const login = async (username: string, password: string) => {
        try {
            setAuthenticating(true);
            // Make API call to authenticate user via server
            await securityService.requestToken(username, password);
            setIsAuthenticated(true);
            setAuthenticating(false);
        } catch (error) {
            console.error('Login failed', error);
            setAuthenticating(false);
            setIsAuthenticated(false);
            throw new Error('Login failed');
        }
    };

    const logout = async () => {
        // Perform logout actions
        await securityService.logout();
        setIsAuthenticated(false);
        //go to login
    };

    useEffect(() => {
        // Check initial authentication status on component mount
        const checkAuthentication = async () => {
            try {
                await securityService.checkAuthentication();
                setIsAuthenticated(true);
                setAuthenticating(false);
            } catch (error) {
                console.error('Error checking authentication status', error);
                setIsAuthenticated(false);
                setAuthenticating(false);
            }
        };
        checkAuthentication();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, authenticating, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
