import React from 'react';
import { AxiosError } from 'axios';
import FailureModal from '../utils/modals/FailureModal'; // Ensure this path is correct

interface AxiosErrorProcessorProps {
  error: AxiosError;
  showModal?: boolean;
  customMessage?: string;
  callback?: () => void
}

const AxiosErrorProcessor: React.FC<AxiosErrorProcessorProps> = ({
  error,
  showModal = false,
  customMessage,
  callback
}) => {
  const [openModal, setOpenModal] = React.useState(showModal);
  const [errorText, setErrorText] = React.useState<string>('');

  React.useEffect(() => {
    let message = 'An unexpected error occurred.';

    if (customMessage) {
      message = customMessage;
    } else if (error.response) {
      switch (error.response.status) {
        case 400:
          message = 'Bad request.';
          break;
        case 401:
          message = 'You are not authenticated. Please log in.';
          break;
        case 403:
          message = 'You do not have permission to access this resource.';
          break;
        case 404:
          message = 'Resource not found.';
          break;
        case 500:
          message = 'Server error. Please try again later.';
          break;
        default:
          message = `An error occurred (status code: ${error.response.status}).`;
      }
    } else if (error.request) {
      message = 'Network error. Please check your connection.';
    } else {
      message = `Error: ${error.message}`;
    }

    setErrorText(message);
    setOpenModal(showModal);
  }, [error, showModal, customMessage]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseWithCallback = () => {
    if (callback) {
      callback()
    }
    
    setOpenModal(false);
  };

  return (
    <FailureModal
      open={openModal}
      onClose={handleClose}
      title="Ha ocurrido un error!"
      onClick={handleCloseWithCallback}
      content={errorText}
    />
  );
};

export default AxiosErrorProcessor;
