import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Paper, rgbToHex, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from "react";
import CartSummary from "./CartSumary";
import { useShoppingCartContext } from "../../context/cartContext";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ShopOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { CartItem } from "../../../models/shopingCart";
import { OrderOutput } from "../../../models/order";
import { decryptData, getEncryptionKey } from "../../../utils/encrypt";
import OrderService from "../../../api/orderService";
import apiConfigInstance from "../../../api/apiConfig";
import { PaymentStatus } from "../payment/paymentStatus";
import { AxiosError } from "axios";
import { useError } from "../../errors/ErrorContext";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface DeleteProductDialogProps {
    open: boolean;
    product: CartItem;
    onClose: () => void;
    onDelete: (productId: string | number) => void;
}

const DeleteProductDialog: React.FC<DeleteProductDialogProps> = ({ open, product, onClose, onDelete }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h6">Deseas eliminar <b>{product.name}</b> del carrito?</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Si continuas el producto se eliminará de forma permanente del carrito.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={() => onDelete(product.id)} autoFocus>
                    Eliminar Producto
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function CartPage() {
    const { products, shipments, removeFromCart } = useShoppingCartContext();
    const [isShipmentSelected, setIsShipmentSelected] = useState<boolean>(false);
    const [openDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<CartItem | null>(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { handleError } = useError()

    const orderService = new OrderService(apiConfigInstance);
    const navigate = useNavigate()

    useEffect(() => {
        const checkOrderPaymentStatus = async () => {
            try {
                const encryptedOrder = localStorage.getItem('currentOrder');
                if (encryptedOrder) {
                    const order: OrderOutput = decryptData(encryptedOrder, getEncryptionKey());
                    const orderData = await orderService.getOrder(order.id);
                    const lastPaymentStatus = orderData.payments[orderData.payments.length - 1].status;
                    if (lastPaymentStatus === PaymentStatus.Processing) {
                        navigate("/payment")
                    }
                }
            } catch (error) {
                const err = error as AxiosError;
                if (err.response?.status === 404) {
                    return;
                }

                handleError(error as Error, true, "Error al verificar el estado de pago de la orden anterior, por favor intentelo de nuevo.", () => checkOrderPaymentStatus());
            }
        };

        checkOrderPaymentStatus();
    }, []);

    const handleClickDelete = (product: CartItem) => {
        setOpenDeleteDialog(true);
        setSelectedProduct(product);
    };

    const removeProductFromCart = (productId: number | string) => {
        setOpenDeleteDialog(false);
        removeFromCart(productId);
    };

    return (
        <Stack spacing={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Stack spacing={1} sx={{ width: "100%" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                    <ShoppingCartIcon color="primary" sx={{ fontSize: 40 }} />
                    <Typography variant="h4">Carrito</Typography>
                </Stack>

                <Divider style={{ backgroundColor: '#ddd', width: '80%' }} />
            </Stack>

            {products.loading ? (
                <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: '200px' }}
                >
                    <CircularProgress />
                    <Typography variant="h6">Cargando productos...</Typography>
                </Stack>
            ) : products.error ? (
                <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: '200px' }}
                >
                    <Stack alignItems={"center"} spacing={1}>
                        <ErrorOutlineIcon fontSize="inherit" style={{ fontSize: "3rem", color: theme.palette.warning.main }} />
                        <Typography variant="h6" >
                            Hubo un error al cargar los productos del carrito.
                        </Typography>
                    </Stack>

                    <Button
                        variant="outlined"
                        sx={{ width: isSmallScreen ? 150 : 300 }}
                        component={Link}
                        to="/"
                    >
                        Volver a la página principal
                    </Button>
                </Stack>
            ) : (
                <TableContainer component={Paper} sx={{ mx: "auto", boxShadow: 2, marginBottom: 4, backgroundColor: "rgb(255,255,255, 0.85)"  }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography fontWeight={"bold"} variant="h6">Producto</Typography></TableCell>
                                <TableCell></TableCell>
                                <TableCell><Typography fontWeight={"bold"} variant="h6">Precio</Typography></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.products.length === 0 ? (
                                <TableRow
                                    key={"no-products"}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell colSpan={4}> {/* Spanning all columns */}
                                        <Stack
                                            display="flex"
                                            spacing={2}
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ minHeight: '100px' }}
                                        >
                                            <Typography variant="h6">Todavia no hay productos en el carrito</Typography>
                                            <Button
                                                variant="outlined"
                                                component={Link}
                                                to="/shop"
                                            >
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <ShopOutlined />
                                                    <Typography>Ir a la Tienda</Typography>
                                                </Stack>
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                products.products.map((product) => (
                                    <TableRow
                                        key={product.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{ paddingRight: '8px' }}>
                                            <img src={product.main_image.data} style={{ width: 64, height: 64, borderRadius: 5 }} alt="Product" />
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                            <Stack spacing={1} direction={isSmallScreen ? "column" : "row"} alignItems={isSmallScreen ? "flex-start" : "center"}>
                                                <Typography>{product.name}</Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>{product.price} €</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleClickDelete(product)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {products.loading || shipments.loading ? (
                <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: '100px' }}
                >
                    <CircularProgress />
                    <Typography variant="h6">Calculando costes...</Typography>
                </Stack>
            ) : products.error || shipments.error ? (
                <Paper sx={{ width: "100%", p: 2, backgroundColor: "rgb(255,255,255, 0.85)" }}>
                    <Stack alignItems={"center"} spacing={1}>
                        <ErrorOutlineIcon fontSize="inherit" style={{ fontSize: "3rem", color: theme.palette.warning.main }} />
                        <Typography variant="h6">
                            No se han podido calcular los costes.
                        </Typography>
                    </Stack>

                </Paper>
            ) : (
                <>
                    <CartSummary onShipmentSelected={() => {
                        setIsShipmentSelected(true);
                    }} />
                    <Button variant="outlined" sx={{ width: isSmallScreen ? 250 : 500, backgroundColor: "white" }} disabled={!isShipmentSelected
                        || products.products.length <= 0
                        || shipments.shipmentTypes.length <= 0}
                        component={Link}
                        to="/payment"><Typography >Finalizar Compra</Typography></Button></>

            )}

            {
                selectedProduct && (
                    <DeleteProductDialog open={openDialog} product={selectedProduct} onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedProduct(null);
                    }} onDelete={(productId) => { removeProductFromCart(productId) }} />
                )
            }
        </Stack>
    );
}
