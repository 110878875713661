import React, { useEffect } from 'react';
import { Link, Button, Container, Paper, Stack, Typography, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { ShopOutlined } from '@mui/icons-material';
import { useShoppingCartContext } from '../../context/cartContext';
import { OrderOutput } from '../../../models/order';
import { decryptData, getEncryptionKey } from '../../../utils/encrypt';

const SuccessPage: React.FC = () => {
    const navigate = useNavigate();
    const { clearCartItems, clearCart } = useShoppingCartContext()
    const theme = useTheme();



    useEffect(() => {
        if (localStorage.getItem("currentOrder") === null) {
            navigate("/shop")
        } else {
            const encryptedOrder = localStorage.getItem('currentOrder');
            if (encryptedOrder) {
                const order: OrderOutput = decryptData(encryptedOrder, getEncryptionKey());
                clearCartItems(order.product_ids as number[])
            } else {
                clearCart()
            }
           
            localStorage.removeItem("currentOrder")
        }

    }, [])


    const handleGoHome = () => {
        navigate('/shop'); // Adjust this to your home page route
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
            <Paper sx={{ opacity: 0.8, p: 4, mx: 'auto', boxShadow: 4, textAlign: 'center' }}>
                <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80, mb: 2 }} />
                <Typography variant="h4" gutterBottom>
                    ¡Gracias por tu compra!
                </Typography>
                <Typography variant="h6" gutterBottom>
                    En breve recibirás un correo electrónico de confirmación en la dirección que has especificado.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Si tienes alguna pregunta o algún problema, no dudes en ponerte en
                    <Link href="/contact" underline="none" sx={{ color: theme.palette.primary.dark, ml: 0.5 }}>
                        contacto.
                    </Link>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 4 }}
                    onClick={handleGoHome}
                >
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <ShopOutlined />
                        <Typography>Volver a la tienda</Typography>
                    </Stack>
                </Button>
            </Paper>
        </Container>
    );
};

export default SuccessPage;
function clearCartItems() {
    throw new Error('Function not implemented.');
}

