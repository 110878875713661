import { Checkbox, FormControlLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ShipmentType } from "../../../models/shipmentType";
import { useShoppingCartContext } from "../../context/cartContext";

interface CartSummaryProps {
    onShipmentSelected?: () => void;
    handleError?: (error: any) => void;
}

const CartSummary: React.FC<CartSummaryProps> = ({ onShipmentSelected, handleError }) => {
    const { shipments, setShipment, getShipment, computePrice } = useShoppingCartContext();
    const [selectedShipment, setSelectedShipment] = useState<ShipmentType | null>(null);

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedShipment = await getShipment();
                if (storedShipment == null) {
                    if (shipments.shipmentTypes.length > 0 && !selectedShipment) {
                        setSelectedShipment(shipments.shipmentTypes[0]);
                        setShipment(shipments.shipmentTypes[0])
                        if (onShipmentSelected) {
                            onShipmentSelected();
                        }
                    }
                } else {
                    setSelectedShipment(storedShipment);
                    if (onShipmentSelected) {
                        onShipmentSelected();
                    }
                }
            } catch (error) {
                if (handleError) {
                    handleError(error);
                } else {
                    console.error("Error fetching shipment data:", error);
                }
            }
        };

        fetchData();
    }, [shipments]);

    const handleShipmentChange = (shipment: ShipmentType) => {
        try {
            setSelectedShipment(shipment);
            setShipment(shipment);
            if (onShipmentSelected) {
                onShipmentSelected();
            }
        } catch (error) {
            if (handleError) {
                handleError(error);
            } else {
                console.error("Error handling shipment change:", error);
            }
        }
    };

    const subtotal = computePrice();
    const total = subtotal + (selectedShipment ? selectedShipment.price : 0);

    return (
        <TableContainer component={Paper} sx={{ mx: "auto", boxShadow: 4, marginBottom: 4 }}>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell><Typography fontWeight={"bold"} variant="h6">Subtotal</Typography></TableCell>
                        <TableCell><Typography variant="body1">{subtotal} €</Typography></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Typography fontWeight={"bold"} variant="h6">Envío</Typography></TableCell>
                        <TableCell>
                            <Stack spacing={1}>
                                {shipments.shipmentTypes.map((shipment) => (
                                    <Stack spacing={1} key={shipment.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedShipment ? selectedShipment.id === shipment.id : false}
                                                    onChange={() => handleShipmentChange(shipment)}
                                                />
                                            }
                                            label={
                                                <Typography>
                                                    {shipment.name} <b>{shipment.price} €</b>
                                                </Typography>
                                            }
                                        />
                                        <Typography variant="body2" sx={{ pl: 4 }}>{shipment.description}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Typography fontWeight={"bold"} variant="h6">Total</Typography></TableCell>
                        <TableCell><Typography variant="body1">{total} €</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CartSummary;
