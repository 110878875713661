import * as CryptoJS from 'crypto-js';

// Encryption key (replace with your own secure key)
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

export function getEncryptionKey() {
    if (encryptionKey === undefined) {
        throw Error("Could not load encryption key.")
    }
    
    return encryptionKey;
}
// Function to encrypt data using AES
export function encryptData(data: any, key: string): string {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return encryptedData;
}

// Function to decrypt data using AES
export function decryptData(encryptedData: string, key: string): any {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}