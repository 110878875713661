import { useEffect, useState } from "react";
import OrdersTable from "./OrdersTable";
import OrderService from "../../../../api/orderService";
import apiConfigInstance from "../../../../api/apiConfig";
import { OrderOutput, OrderOutputFull } from "../../../../models/order";
import { Stack, Typography, Select, MenuItem, SelectChangeEvent, Paper, useTheme } from "@mui/material";
import ConfirmationModal from "../../../utils/modals/ConfirmationModal";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const OrdersSection = () => {
    const ordersService = new OrderService(apiConfigInstance);
    const [ordersData, setOrdersData] = useState<OrderOutputFull[]>([]);
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>("Hoy");
    const [confirmationData, setConfirmationData] = useState<{ open: boolean, title: string, text: string, fn: () => void }>({
        open: false, title: "", text: "", fn: () => { }
    })

    const [error, setError] = useState<boolean>(false)

    const theme = useTheme()

    useEffect(() => {
        fetchOrders(selectedTimeRange); // Fetch orders when component mounts or selectedTimeRange changes
    }, [selectedTimeRange]);

    const getStartDate = (timeRange: string): string => {
        const today = new Date();
        let startDate: Date;

        switch (timeRange) {
            case "today":
                startDate = new Date(today);
                break;
            case "last10days":
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 9);
                break;
            case "last30days":
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 29);
                break;
            case "lastyear":
                startDate = new Date(today);
                startDate.setFullYear(today.getFullYear() - 1);
                break;
            default:
                startDate = new Date();
        }

        return startDate ? startDate.toISOString().slice(0, 19).replace('T', ' ') : ""; // Format: "YYYY-MM-DD HH:MM:SS"
    };

    const getEndDate = (): string => {
        const today = new Date();
        return today.toISOString().slice(0, 19).replace('T', ' '); // Format: "YYYY-MM-DD HH:MM:SS"
    };

    const fetchOrders = (timeRange: string) => {
        let promise;

        switch (timeRange) {
            case "Hoy":
                promise = ordersService.getOrdersFull({ startDate: getStartDate("today"), endDate: getEndDate() });
                break;
            case "Ultimos 10 dias":
                promise = ordersService.getOrdersFull({ startDate: getStartDate("last10days"), endDate: getEndDate() });
                break;
            case "Ultimos 30 dias":
                promise = ordersService.getOrdersFull({ startDate: getStartDate("last30days"), endDate: getEndDate() });
                break;
            case "1 año":
                promise = ordersService.getOrdersFull({ startDate: getStartDate("lastyear"), endDate: getEndDate() });
                break;
            case "Todos":
                promise = ordersService.getOrdersFull();
                break;
            default:
                promise = ordersService.getOrdersFull();
        }

        promise.then((orders) => {
            setOrdersData(orders);
        }).catch((error) => {
            setError(true)
        });
    };

    const handleTimeRangeChange = (event: SelectChangeEvent<string>) => {
        setSelectedTimeRange(event.target.value);
    };

    const handleDelete = (orderId: string | number) => {
        setConfirmationData({
            open: true,
            title: "Eliminar Pedido",
            text: "Si continuas el pedido será eliminado.",
            fn: () => {
                ordersService.deleteOrder(orderId).then(() => {
                    setOrdersData((prevOrders) => prevOrders.filter(order => order.id !== orderId));
                    setConfirmationData({ open: false, title: "", text: "", fn: () => { } });
                }).catch((error) => {
                    console.error('Error deleting order:', error);
                    // Handle error
                });
            }
        });
    };

    const handleOrderStatusUpdate = (orderId: string | number, status: string) => {
        ordersService.updateOrderStatus(orderId, status).then((updatedOrder: OrderOutput) => {
            setOrdersData((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === orderId ? { ...order, status: updatedOrder.status } : order
                )
            );
        }).catch((error) => {
            console.error('Error updating order status:', error);
            // Handle error
        });
    };

    return (
        <Paper sx={{ p: 2, backgroundColor: "rgb(255, 255, 255, 0.8)" }}>
            <Stack spacing={2}>
                <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography fontSize={"30px"}>Pedidos</Typography>
                    {
                        error && (

                            <Select
                                value={selectedTimeRange}
                                onChange={handleTimeRangeChange}
                                variant="outlined"
                                style={{ minWidth: 150 }}
                            >
                                <MenuItem value="Hoy">Hoy</MenuItem>
                                <MenuItem value="Ultimos 10 dias">Últimos 10 días</MenuItem>
                                <MenuItem value="Ultimos 30 dias">Últimos 30 días</MenuItem>
                                <MenuItem value="1 año">Último año</MenuItem>
                                <MenuItem value="Todos">Todos</MenuItem>
                            </Select>
                        )
                    }

                </Stack>

                {
                    error ? (
                        <Stack spacing={2} alignItems="center" sx={{ textAlign: 'center', p: 4 }}>

                            <ErrorOutlineIcon fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                            <Typography variant="h6">No se han podido cargar los datos.</Typography>
                        </Stack>
                    ) : (
                        <>
                            <OrdersTable orders={ordersData} onDeleteOrder={(orderId) => {
                                handleDelete(orderId);
                            }} onUpdateOrderStatus={(orderId, status) => {
                                handleOrderStatusUpdate(orderId, status)
                            }} />
                            <ConfirmationModal open={confirmationData.open} onClose={() => setConfirmationData({
                                open: false, title: "", text: "", fn: () => { }
                            })
                            } onConfirm={() => confirmationData.fn()} title={confirmationData.title} content={<Typography>{confirmationData.text}</Typography>} confirmText={"Borrar Pedido"} cancelText={"Cerrar"} />
                        </>
                    )
                }


            </Stack>
        </Paper>
    );
};

export default OrdersSection;
