import { Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useState, useEffect } from "react";
import { useAuth } from "../../context/securityContext";
import { useNavigate } from "react-router-dom";
import FailureModal from "../../utils/modals/FailureModal";

const LoginPage = () => {

    const theme = useTheme();
    const { login } = useAuth();
    const navigate = useNavigate();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoding] = useState<boolean>(false);

    const [errorData, setErrorData] = useState<{ open: boolean, title: string, content: string }>({
        open: false,
        title: "",
        content: ""
    })

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter" && username !== "" && password !== "") {
                handleLogin();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [username, password]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleLogin = async () => {
        try {
            setLoding(true);
            await login(username, password);

            navigate("/admin");
        } catch {
            setLoding(false);
            setErrorData({
                open: true,
                title: "Error de acceso",
                content: "Ha ocurrido un error durante el proceso de autenticación, por favor revisa que las credenciales sean válidas."
            });
        }
    };

    return (
        <Paper sx={{ p: 4, backgroundColor: "rgb(255,255,255,0.8)" }}>
            <>
                <Stack
                    spacing={3}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '50vh' }}
                >
                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mb: 2 }}>
                        <AdminPanelSettingsIcon fontSize="large" />
                        <Typography variant={isMobile ? "h5" : "h4"}>Administradores</Typography>
                    </Stack>

                    <img src="./images/logo/NayadeLifeLogo.png" alt="NadaLife Logo" style={{ width: isMobile ? 150 : 200, height: 'auto' }} />
                    {
                        loading ?
                            <Stack alignItems={"center"} spacing={3}>
                                <Typography variant="h6">Accediendo...</Typography>
                                <CircularProgress />
                            </Stack>

                            : <Stack alignItems={"center"} spacing={4} width={"100%"}>
                                <FormControl sx={{ m: 1, width: isMobile ? "75%" : "50%" }} variant="standard">
                                    <Stack spacing={4}>
                                        <InputLabel htmlFor="username" sx={{ fontSize: "1.5rem", color: "black", '&.Mui-focused': { color: theme.palette.primary.dark } }}>Usuario</InputLabel>
                                        <Input
                                            id="username"
                                            type="text"
                                            value={username}
                                            onChange={(e) => {
                                                setUsername(e.target.value)
                                            }}
                                            startAdornment={<InputAdornment position="start"><AccountCircleIcon fontSize="large" /></InputAdornment>}
                                            sx={{
                                                color: "black",
                                                '&.Mui-focused:before': {
                                                    borderBottomColor: theme.palette.primary.dark,
                                                },
                                                '&.Mui-focused:after': {
                                                    borderBottomColor: theme.palette.primary.dark,
                                                    fontWeight: "bold"
                                                }
                                            }}
                                        />
                                    </Stack>
                                </FormControl >
                                <FormControl sx={{ mt: 1, width: isMobile ? "75%" : "50%" }} variant="standard">
                                    <Stack spacing={4}>
                                        <InputLabel htmlFor="password" sx={{ fontSize: "1.5rem", color: "black", '&.Mui-focused': { color: theme.palette.primary.dark } }}>Contraseña</InputLabel>
                                        <Input
                                            id="password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start"><VpnKeyIcon fontSize="large" /></InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                color: "black",
                                                '&.Mui-focused:before': {
                                                    borderBottomColor: theme.palette.primary.dark,
                                                },
                                                '&.Mui-focused:after': {
                                                    borderBottomColor: theme.palette.primary.dark,
                                                }
                                            }}
                                        />
                                    </Stack>
                                </FormControl>
                                <Button disabled={username === "" || password === ""} variant="outlined" sx={{ width: 200, borderRadius: '32px', color: theme.palette.primary.dark, backgroundColor: 'rgba(255, 255, 255, 0.7)' }} onClick={handleLogin}>
                                    Acceder
                                </Button>
                            </Stack>
                    }

                </Stack>
                <FailureModal open={errorData.open} onClose={() => {
                    setErrorData({
                        open: false,
                        title: "",
                        content: ""
                    });
                }} title={errorData.title} content={errorData.content} />
            </>
        </Paper>
    );
};

export default LoginPage;
