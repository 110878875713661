import { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import ProductImageList from './ProductList';
import ProductService from '../../../api/productService';
import apiConfigInstance from '../../../api/apiConfig';
import { ProductData } from '../../../models/product';
import CategoryService from '../../../api/categoryService';
import { Category } from '../../../models/category';
import { Button, CircularProgress, Divider, Paper, Stack } from '@mui/material';
import { ShopOutlined, SentimentDissatisfiedOutlined } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useError } from '../../errors/ErrorContext';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';

const ShopPage = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true); // New state for categories loading
  const categoryService = new CategoryService(apiConfigInstance);
  const productService = new ProductService(apiConfigInstance);
  const [selectedCategory, setSelectedCategory] = useState<Category>(); // State to store the name of the selected category
  const [productDataState, setProductDataState] = useState<{ loading: boolean, data: ProductData[] }>({ loading: true, data: [] });
  const productLookUp = useRef<Map<string | number, ProductData[]>>(new Map<string | number, ProductData[]>());
  const theme = useTheme();

  const { handleError } = useError();  // Use the error handling context
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const categoryId = params.get("categoryId");

    categoryService.getCategories().then((categories) => {
      setCategories(categories);
      setCategoriesLoading(false); // Set categories loading to false after fetching

      // Find the category based on categoryId and update selectedCategory
      const foundCategory = categories.find((cat) => String(cat.id) === categoryId);
      let currentCategory: Category | null = null;
      if (foundCategory) {
        currentCategory = foundCategory;
        setSelectedCategory(foundCategory);
      } else {
        if (categories.length > 0) {
          setSelectedCategory(categories[0]);
          currentCategory = categories[0];
        }
      }

      // Fetch products after categories are fetched
      productService.getProducts(currentCategory ? currentCategory.id : undefined).then((products) => {
        if (currentCategory) {
          productLookUp.current.set(currentCategory.id, products);
        }
        setProductDataState({ loading: false, data: products });
      }).catch((error: AxiosError) => {
        handleError(error, false, "No se han podido cargar los productos.");
        setProductDataState({ loading: false, data: [] });
      });

    }).catch((error: AxiosError) => {
      handleError(error, false, "No se han podido cargar las categorías.");
      setCategoriesLoading(false); // Set categories loading to false if there's an error
      setProductDataState({ loading: false, data: [] });
    });
  }, []);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory({ ...category });

    // Check if products for this category are already cached
    if (productLookUp.current.has(category.id)) {
      setProductDataState({ loading: false, data: productLookUp.current.get(category.id)! });
    } else {
      // If not cached, fetch the products and update the state
      setProductDataState(prevData => ({ ...prevData, loading: true, data: prevData.data }));
      productService.getProducts(category.id).then((products) => {
        // Cache the fetched products
        productLookUp.current.set(category.id, products);
        setProductDataState({ loading: false, data: products });
      }).catch((error: AxiosError) => {
        handleError(error, false, "No se han podido cargar los productos.");
        setProductDataState({ loading: false, data: [] });
      });
    }
  };

  return (
    <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
      <Grid container display="flex" justifyContent="center" spacing={2}>
        {/* Left side: Categories */}
        <Grid item xs={12} container alignItems="center" justifyContent="center" spacing={1} sx={{ mb: 2 }}>
          <Grid item><ShopOutlined fontSize="large" color="primary"></ShopOutlined></Grid>
          <Grid item><Typography variant="h4" gutterBottom>Tienda</Typography></Grid>
          <Grid item xs={12} container alignItems="center" justifyContent="center">
            <Divider style={{ backgroundColor: '#ddd', width: '80%' }} />
          </Grid>
        </Grid>

        <Grid item xs={10} md={2}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <CategoryIcon fontSize='medium' sx={{color: theme.palette.primary.main}}></CategoryIcon>
            <Typography variant="h5"  gutterBottom>Categorías</Typography>
          </Stack>
          
          {categoriesLoading ? (
            <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
              <CircularProgress />
              <Typography variant="body2" sx={{ mt: 2 }}>Cargando categorías...</Typography>
            </Stack>
          ) : categories.length === 0 ? (
            <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
              <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "2rem", color: theme.palette.warning.main }} />
              <Typography variant="body2" sx={{ mb: 2 }}>No se han encontrado categorías</Typography>
            </Stack>
          ) : (
            <List>
              {categories.map((category, idx) => (
                <ListItemButton
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  sx={{
                    bgcolor: selectedCategory?.id === category.id ? theme.palette.grey[400] : 'transparent',
                    color: selectedCategory?.id === category.id ? theme.palette.primary.contrastText : 'inherit',
                    '&:hover': {
                      bgcolor: theme.palette.grey[300],
                    },
                  }}
                >
                  <ListItemText primary={category.name} />
                </ListItemButton>
              ))}
            </List>
          )}
        </Grid>

        {/* Right side: Products */}
        <Grid item xs={11} md={8} sx={{ pt: 2, pb: 4 }}>
          <Typography
            variant="h5" // Larger size for the category title
            gutterBottom
            sx={{
              fontWeight: "bold", // Bold for emphasis
              textAlign: "center", // Center-align for emphasis
              borderRadius: 2, // Optional rounded corners for the background
              p: 2 // Add padding for better spacing
            }}
          >
            {selectedCategory ? selectedCategory.name : "Productos"}
          </Typography>
          <Divider sx={{mb: 1}}></Divider>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {productDataState.loading ? (
                <Stack sx={{ textAlign: 'center', alignItems: "center", mt: 2 }}>
                  <CircularProgress />
                  <Typography variant="body2" sx={{ mt: 2 }}>Cargando productos  ...</Typography>
                </Stack>
              ) : productDataState.data.length === 0 ? (
                <Stack sx={{ textAlign: 'center', alignItems: "center" }}>
                  <SentimentDissatisfiedOutlined fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>No se han encontrado productos</Typography>
                  <Button variant="outlined" sx={{ backgroundColor: "white", opacity: 0.9 }} onClick={() => navigate("/")}>
                    <Typography>Volver a la pantalla de inicio</Typography>
                  </Button>
                </Stack>
              ) : (
                <ProductImageList products={productDataState.data} mobileCols={2} fullWidthCols={3} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </Paper>
  );
}

export default ShopPage;
