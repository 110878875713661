import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { AdminProvider } from "./AdminContext";
import AdminTabs from "./AdminTabs";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const AdminPage = () => {

    return (
        <AdminProvider>
            <Container>
                <Stack direction={"row"} spacing={1} alignItems={"center"} >
                <AdminPanelSettingsIcon fontSize="large"/> 
                    <Typography fontSize={"32px"} textAlign={"left"} sx={{ mt: 2, mb: 4 }}>Administración</Typography>
                   
                </Stack>

                <AdminTabs />
            </Container>
        </AdminProvider>
    );
}

export default AdminPage;

