import { AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { OrderInput, OrderOutput } from "../models/order";

class PaymentService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    async checkout(orderId: Number): Promise<{ sessionId: string }> {
        try {
            const response = await this.axiosInstance.post<{ order: OrderOutput, session_id: string }>(
                `/payment/create_checkout_session?order_id=${orderId}`
            );
            return {
                sessionId: response.data.session_id
            };
        } catch (error) {
            console.error("Error creating checkout session", error);
            throw new Error("Failed to create checkout session");
        }
    }
}

export default PaymentService;