import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Typography, IconButton, Divider } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

interface AddCategoryModalProps {
    open: boolean;
    onClose: () => void;
    onAddCategory: (category: { name: string; description: string }) => void;
}

interface FormState {
    name: string;
    description: string;
    errors: {
        name: boolean;
    };
    touched: {
        name: boolean;
    };
}

const initialFormState: FormState = {
    name: "",
    description: "",
    errors: {
        name: false,
    },
    touched: {
        name: false,
    },
};

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({ open, onClose, onAddCategory }) => {
    const [formState, setFormState] = useState<FormState>(initialFormState);

    const handleFieldChange = (field: keyof FormState, value: string) => {
        setFormState({
            ...formState,
            [field]: value,
            errors: {
                ...formState.errors,
                [field]: field === "name" ? !value.trim() : false,
            },
            touched: {
                ...formState.touched,
                [field]: true,
            },
        });
    };

    const handleAddCategory = () => {
        const { name, description } = formState;
        const errors = {
            name: !name.trim(),
        };
        const touched = {
            name: true,
        };

        setFormState({ ...formState, errors, touched });

        if (!errors.name) {
            onAddCategory({ name, description });
            setFormState(initialFormState);
            onClose();
        }
    };

    const handleClose = () => {
        setFormState(initialFormState);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Stack direction={"row"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <AddIcon fontSize="large"/>
                        <Typography variant="body1">Añadir Categoría</Typography>
                    </Stack>
                    <IconButton onClick={handleClose}><CloseIcon></CloseIcon></IconButton>
                </Stack>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nombre"
                    type="text"
                    fullWidth
                    value={formState.name}
                    onChange={(e) => handleFieldChange("name", e.target.value)}
                    error={formState.errors.name}
                    helperText={formState.errors.name && "El nombre es obligatorio."}
                    required // Add the required attribute
                />
                <TextField
                    margin="dense"
                    label="Descripción"
                    type="text"
                    multiline
                    rows={3}
                    fullWidth
                    value={formState.description}
                    onChange={(e) => handleFieldChange("description", e.target.value)}
                />
            </DialogContent>
            <DialogActions sx={{p: 2}}>
                <Button color="error" variant="outlined" onClick={handleClose}><Typography variant="body2">Cancelar</Typography></Button>
                <Button
                    onClick={handleAddCategory}
                    variant="outlined"
                    color="primary"
                    disabled={formState.errors.name}
                >
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <AddIcon />
                        <Typography variant="body2">Añadir</Typography>
                    </Stack>

                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCategoryModal;