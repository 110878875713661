import React from 'react';
import { Dialog, DialogContent, CircularProgress, Typography } from '@mui/material';

interface OrderValidationDialogProps {
  open: boolean;
}

const OrderValidationDialog: React.FC<OrderValidationDialogProps> = ({ open }) => {
  return (
    <Dialog open={open} aria-labelledby="order-validation-dialog-title">
      <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress color="primary" size={24} style={{ marginRight: '16px' }} />
          <Typography variant="body1">Validando orden...</Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OrderValidationDialog;