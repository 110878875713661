import { Box, Divider, Skeleton, Stack, Typography, useTheme, CircularProgress, useMediaQuery } from "@mui/material";
import FeaturedProducts from "./FeaturedProducts";
import React, { useEffect, useState } from "react";
import { Category } from "../../../models/category";
import CategoryService from "../../../api/categoryService";
import apiConfigInstance from "../../../api/apiConfig";
import CategoryList from "./CategoryList";
import FeaturedProductService from "../../../api/featuredProductsService";
import { ProductData } from "../../../models/product";
import CarouselImageService from "../../../api/carouselService";
import { CarouselImageOutput } from "../../../models/carousel";
import ImageCarousel from "./ImageCarusel";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const HomePage = () => {
  const theme = useTheme();

  const [categoriesState, setCategories] = useState<{ loading: boolean, categories: Category[] }>({ loading: false, categories: [] })
  const [featuredProductsState, setFeaturedProductsState] = useState<{ loading: boolean, featuredProducts: ProductData[] }>({ loading: false, featuredProducts: [] })
  const [carouselImagesState, setCarouselImagesState] = useState<{ loading: boolean, images: CarouselImageOutput[] }>({ loading: true, images: [] })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getCategories = async () => {
      setCategories({ loading: true, categories: [] });
      const categoryService = new CategoryService(apiConfigInstance);
      try {
        const categories = await categoryService.getCategories();
        setCategories({ loading: false, categories });
      } catch {
        console.log("Could not load categories");
        setCategories({ loading: false, categories: [] });
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      setFeaturedProductsState({ loading: true, featuredProducts: [] });
      try {
        const featProducts = await new FeaturedProductService(apiConfigInstance).getAllFeaturedProducts();
        setFeaturedProductsState({ loading: false, featuredProducts: featProducts });
      } catch (error) {
        setFeaturedProductsState({ loading: false, featuredProducts: [] });
      }
    };

    fetchFeaturedProducts();
  }, []);

  useEffect(() => {
    const fetchCarouselImages = async () => {
      setCarouselImagesState({ loading: true, images: [] });
      try {
        const carouselImages = await new CarouselImageService(apiConfigInstance).getCarouselImages();
        setCarouselImagesState({ loading: false, images: carouselImages });
      } catch (error) {
        console.log("Could not load carousel images");
        setCarouselImagesState({ loading: false, images: [] });
      }
    };

    fetchCarouselImages();
  }, []);

  return (
    <Stack
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: -2 }}
    >
      <Box width={"100%"}>
        {carouselImagesState.loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? 300 : 600,// Same height as the carousel to keep layout consistent
            }}
          >
            <CircularProgress />
          </Box>
        ) : carouselImagesState.images.length > 0 ? (
          <ImageCarousel images={carouselImagesState.images} />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? 300 : 600,
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <Stack spacing={1} alignItems={"center"}>
              <SentimentDissatisfiedIcon fontSize="large" sx={{ color: theme.palette.grey[600] }} />
              <Typography variant="h6" color="textSecondary" textAlign={"center"}>
                No se han podido cargar las imágenes.
              </Typography>
            </Stack>

          </Box>
        )}
      </Box>

      {featuredProductsState.loading ? (
        <Stack alignItems={"center"} minWidth={"100%"}>
          <Typography variant="h5" fontWeight="bold" sx={{ mt: 4, mb: 2 }}>Destacados</Typography>
          <Skeleton variant="rectangular" width={"90%"} height={"25vh"} sx={{ mt: 2 }} />
        </Stack>

      ) : featuredProductsState.featuredProducts.length > 0 && (
        <Stack alignItems={"center"}>
          <Typography variant="h5" fontWeight="bold" sx={{ mt: 4, mb: 2 }}>Destacados</Typography>
          <Divider sx={{ width: "100%", borderColor: theme.palette.secondary.main, borderWidth: 1 }} />
          <FeaturedProducts featuredProducts={featuredProductsState.featuredProducts} />
        </Stack>
      )}

      <Typography variant="h5" fontWeight="bold" sx={{ mt: 4, mb: 2 }}>Categorías</Typography>
      <Divider sx={{ width: "90%", borderColor: theme.palette.secondary.main, borderWidth: 1, mb: 2 }} />
      {categoriesState.loading ? (
        <Skeleton variant="rectangular" width={"90%"} height={"15vh"} />
      ) : categoriesState.categories.length > 0 ? (
        <CategoryList categories={categoriesState.categories} />
      ) : (
        <Box
          sx={{
            width: "90%",
            height: "15vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Stack spacing={1} alignItems={"center"}>
              <SentimentDissatisfiedIcon fontSize="large" sx={{ color: theme.palette.grey[600] }} />
              <Typography variant="h6" color="textSecondary" textAlign={"center"}>
                No se han podido cargar las categorías.
              </Typography>
            </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default HomePage;
