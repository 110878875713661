import { AxiosInstance } from 'axios';
import { ShipmentType, ShipmentTypeInput } from '../models/shipmentType';
import { ApiConfig } from './apiConfig';

class ShipmentTypeService {
  axiosInstance: AxiosInstance;

  constructor(apiConfig: ApiConfig) {
    this.axiosInstance = apiConfig.axiosInstance;
  }

  async createShipmentType(shipmentTypeData: ShipmentTypeInput): Promise<ShipmentType> {
    try {
      const response = await this.axiosInstance.post('/shipments', shipmentTypeData);
      return response.data;
    } catch (error) {
      console.error('Error creating shipment type:', error);
      throw error;
    }
  }

  async getShipmentType(shipmentTypeId: number): Promise<ShipmentType> {
    try {
      const response = await this.axiosInstance.get(`/shipments/${shipmentTypeId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching shipment type:', error);
      throw error;
    }
  }

  async getShipmentTypes(): Promise<ShipmentType[]> {
    try {
      const response = await this.axiosInstance.get(`/shipments`);
      return response.data;
    } catch (error) {
      console.error('Error fetching shipment types:', error);
      throw error;
    }
  }

  async updateShipmentType(shipmentTypeId: number | string, shipmentTypeData: ShipmentType): Promise<ShipmentType> {
    try {
      const response = await this.axiosInstance.put(`/shipments/${shipmentTypeId}`, shipmentTypeData);
      return response.data;
    } catch (error) {
      console.error('Error updating shipment type:', error);
      throw error;
    }
  }

  async deleteShipmentType(shipmentTypeId: number | string): Promise<ShipmentType> {
    try {
      const response = await this.axiosInstance.delete(`/shipments/${shipmentTypeId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting shipment type:', error);
      throw error;
    }
  }
}

export default ShipmentTypeService;