import { createTheme, lighten } from "@mui/material/styles";
import backgroundImage from '../assets/enhanced_image.png'
import '@fontsource/montserrat'; // Defaults to weight 400

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3', // Sky Blue
      light: lighten('#2196f3', 0.8), // Lighter shade of Sky Blue with more white
    },
    secondary: {
      main: '#64b5f6', // Lighter Blue
      light: lighten('#64b5f6', 0.8), // Lighter shade of Lighter Blue with more white
    },
    background: {
      default: '#ffffff', // White
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      fontSize: '1.125rem', // Default size for larger screens
      '@media (max-width:600px)': {
        fontSize: '1.125rem', // Increase size for mobile screens
      },
    },
    body2: {
      fontSize: '1rem', // Default size for larger screens
      '@media (max-width:600px)': {
        fontSize: '1rem', // Increase size for mobile screens
      },
    },
    // You can specify other typography styles here as well
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundAttachment: 'fixed', // Fixed background attachment
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::before': {
            content: '""',
            position: 'fixed', // Fixed positioning for the overlay
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // White overlay with 60% opacity
            zIndex: -1,
          },
        },
      },
    },
  },
});

export default theme;