import { AxiosInstance } from 'axios';
import { ApiConfig } from './apiConfig';
import { TokenData } from '../models/security';

class SecurityService {

  axiosInstance: AxiosInstance;

  constructor(apiConfig: ApiConfig) {
    this.axiosInstance = apiConfig.axiosInstance;
  }

  // Method to request an access token
  async requestToken(username: string, password: string): Promise<void> {
    try {
      const params = new URLSearchParams();
      params.append('grant_type', '');
      params.append('username', username);
      params.append('password', password);
      params.append('scope', '');
      params.append('client_id', '');
      params.append('client_secret', '');

      const response = await this.axiosInstance.post('/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      console.log(response)
    } catch (error) {
      console.error('Error requesting token:', error);
      throw error;
    }
  }

  async checkAuthentication() {
    try {
      await this.axiosInstance.get(`/check_auth`);
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  async logout() {
    try {
      await this.axiosInstance.post(`/logout`);
    } catch (error) {
      console.error('Error loging out:', error);
      throw error;
    }
  }
}

export default SecurityService;