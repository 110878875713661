import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton,
    useTheme
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { UserOutput } from '../../../../models/user';
import ConfirmationModal from '../../../utils/modals/ConfirmationModal';

interface UsersTableProps {
    users: UserOutput[];
    onEdit: (user: UserOutput) => void;
    onDelete: (id: number) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, onEdit, onDelete }) => {


    const theme = useTheme()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="user table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Nombre</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Apellido</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Correo Electrónico</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Nombre de Usuario</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align="center">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.last_name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell align="center">
                                <IconButton
                                    color="primary"
                                    aria-label="edit user"
                                    onClick={() => onEdit(user)}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    sx={{ color: theme.palette.error.light }}
                                    aria-label="delete user"
                                    onClick={() => onDelete(user.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersTable;