import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const Figure = styled.figure`
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 25vh;
  background-position: 50% 50%;
  background-color: #eee;
  margin: 0;
  overflow: hidden;
  cursor: zoom-in;
  &:before {
    content: "";
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #333;
    border-right-color: #333;
    border-bottom-color: #333;
    animation: ${rotate} 2s linear infinite;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
  }
  &.loaded {
    min-height: auto;
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 0;
    }
  }
`;

const ErrorText = styled.p`
  width: 100%;
  text-align: center;
  border: 1px solid #f8f8f8;
  padding: 8px 16px;
  border-radius: 8px;
  color: #555;
`;

const Img = styled.img`
  transition: opacity 0.8s;
  display: block;
`;

interface ImageZoomProps {
  circular?: boolean;
  zoom?: string;
  alt?: string;
  width?: string;
  height?: string;
  src: string;
  id?: string;
  className?: string;
  onError?: (error: any) => void;
  errorContent?: React.ReactNode;
  onClick?: () => void;
}

const ImageZoom: React.FC<ImageZoomProps> = ({
  zoom = "200",
  alt = "This is an imageZoom image",
  width = "100%",
  height = "auto",
  circular = true,
  src,
  id,
  className,
  onError,
  errorContent = <ErrorText>There was a problem loading your image</ErrorText>,
  onClick,
}) => {
  const [zoomed, setZoomed] = useState("1");
  const [position, setPosition] = useState("50% 50%");
  const [imgData, setImgData] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [zoomEnabled, setZoomEnabled] = useState(false); // Add state to track zoom enablement
  const figureClass = imgData ? "loaded" : "loading";
  const figureZoomed = zoomed === "0" ? "zoomed" : "fullView";

  const zoomInPosition = (e: React.MouseEvent<HTMLElement>) => {
    if (!zoomEnabled) return; // Only zoom if zoomEnabled is true
    const zoomer = e.currentTarget.getBoundingClientRect();
    let x = ((e.clientX - zoomer.x) / zoomer.width) * 100;
    let y = ((e.clientY - zoomer.y) / zoomer.height) * 100;
    setPosition(`${x}% ${y}%`);
  };

  const toggleZoomImage = (isZoomed: boolean) => {
    if (!zoomEnabled) return; // Only change zoom state if zoomEnabled is true
    setZoomed(isZoomed ? "0" : "1");
  };

  const handleMove = (e: React.MouseEvent<HTMLElement>) => {
    zoomInPosition(e);
    toggleZoomImage(true);
  };

  const handleLeave = () => {
    setPosition("50% 50%"); // Reset position to center
    setZoomed("1"); // Reset zoom to the original (unzoomed) state
    setZoomEnabled(false); // Disable zoom when leaving the image
  };

  const handleImageClick = () => {
    if (zoomEnabled) {
      setZoomEnabled(false);
      setPosition("50% 50%"); // Reset position to center
      setZoomed("1"); // Reset zoom to the original (unzoomed) state
    } else {
      setZoomEnabled(true);
    }
    // Toggle zoom enablement on click
    if (onClick) onClick();
  };

  useEffect(() => {
    let img = new Image();
    img.src = src;
    setImgData(img.src);
    setZoomed("1");
  }, [src]);

  if (error) {
    return <>{errorContent}</>;
  } else {
    return (
      <Figure
        id={id}
        className={[figureClass, figureZoomed, className || ""].join(" ")}
        style={{
          backgroundImage: `url(${zoomed === "0" ? imgData || "" : ""})`,
          backgroundSize: zoom + "%",
          backgroundPosition: position,
          borderRadius: circular ? "50%" : "",
          width: width,
          height: height,
        }}
        onMouseMove={(e) => handleMove(e)}
        onMouseLeave={handleLeave} // Reset zoom on mouse leave
        onClick={handleImageClick} // Enable zoom on click
      >
        <Img
          id="imageZoom"
          src={imgData || ""}
          alt={alt}
          style={{
            opacity: zoomed,
            width: width,
            height: height,
          }}
          width={width}
          height={height}
        />
      </Figure>
    );
  }
};

export default ImageZoom;
