import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Category } from '../../../models/category';
import { ProductData } from '../../../models/product';
import CategoryService from '../../../api/categoryService';
import apiConfigInstance from '../../../api/apiConfig';
import ProductService from '../../../api/productService';
import ShipmentTypeService from '../../../api/shipmentTypeService'; 
import { ShipmentType } from '../../../models/shipmentType';
import FeaturedProductService from '../../../api/featuredProductsService';
import CarouselImageService from '../../../api/carouselService';
import { CarouselImageOutput } from '../../../models/carousel';
import { AxiosError } from 'axios'; 
import { Configs } from '../../../models/configs';
import ConfigService from '../../../api/configService';
import UserService from '../../../api/userService'; // Import UserService
import { UserInput, UserOutput } from '../../../models/user'; // Import User types

// Define the interfaces for each tab's state
export interface CategoriesState {
  categories: Category[];
  loading: boolean;
  error: Error | AxiosError | null;
}

export interface ProductsState {
  products: ProductData[];
  loading: boolean;
  error: Error | AxiosError | null;
}

export interface ShipmentTypesState {
  shipmentTypes: ShipmentType[];
  loading: boolean;
  error: Error | AxiosError | null;
}

export interface ConfigsState {
  configs: Configs | null;
  loading: boolean;
  error: Error | AxiosError | null;
}

export interface ProductsLookup {
  products: Map<string | number, ProductsState>;
}

export interface FeaturedProductsState {
  featuredProductIds: Set<number | string>;
  loading: boolean;
  error: Error | AxiosError | null;
}

export interface CarouselImagesState {
  images: CarouselImageOutput[];
  loading: boolean;
  error: Error | AxiosError | null;
}

export interface UsersState {
  users: UserOutput[];
  loading: boolean;
  error: Error | AxiosError | null;
}

// Define the initial states
const initialCategoriesState: CategoriesState = {
  categories: [],
  loading: true,
  error: null
};

const initialShipmentTypesState: ShipmentTypesState = {
  shipmentTypes: [],
  loading: true,
  error: null
};

const initialConfigsState: ConfigsState = {
  configs: null,
  loading: true,
  error: null,
};

const initialUsersState: UsersState = {
  users: [],
  loading: true,
  error: null,
};

// Define the interface for the admin context
interface AdminContextType {
  categoriesState: CategoriesState;
  setCategoriesState: React.Dispatch<React.SetStateAction<CategoriesState>>;
  productsLookup: ProductsLookup;
  setProductsLookup: React.Dispatch<React.SetStateAction<ProductsLookup>>;
  setProductsByCategory: (categoryId: string | number, products: ProductData[]) => void;
  appendProductByCategory: (categoryId: string | number, product: ProductData) => void;
  updateProductByCategory: (categoryId: string | number, product: ProductData) => void;
  deleteProductByCategory: (categoryId: string | number, productId: number | string) => void;
  shipmentTypesState: ShipmentTypesState;
  featuredProductsState: FeaturedProductsState;
  addFeaturedProduct: (productId: number) => void;
  removeFeaturedProduct: (productId: number) => void;
  setShipmentTypesState: React.Dispatch<React.SetStateAction<ShipmentTypesState>>;
  carouselImagesState: CarouselImagesState;
  setCarouselImagesState: React.Dispatch<React.SetStateAction<CarouselImagesState>>;
  configsState: ConfigsState;
  setConfigsState: React.Dispatch<React.SetStateAction<ConfigsState>>;
  updateConfigs: (updatedConfigs: Configs) => Promise<void>;
  usersState: UsersState;
  setUsersState: React.Dispatch<React.SetStateAction<UsersState>>;
  addUser: (userInput: UserInput) => Promise<void>;
  updateUser: (id: number, userInput: UserInput) => Promise<void>;
  deleteUser: (id: number) => Promise<void>;
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const AdminProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [categoriesState, setCategoriesState] = useState<CategoriesState>(initialCategoriesState);
  const [shipmentTypesState, setShipmentTypesState] = useState<ShipmentTypesState>(initialShipmentTypesState);
  const [productsLookup, setProductsLookup] = useState<ProductsLookup>({ products: new Map() });
  const [featuredProductsState, setFeaturedProductsState] = useState<FeaturedProductsState>({ featuredProductIds: new Set(), loading: true, error: null });
  const [carouselImagesState, setCarouselImagesState] = useState<CarouselImagesState>({ loading: true, images: [], error: null });
  const [configsState, setConfigsState] = useState<ConfigsState>(initialConfigsState);
  const [usersState, setUsersState] = useState<UsersState>(initialUsersState);

  // API Services
  const categoryService = new CategoryService(apiConfigInstance);
  const productService = new ProductService(apiConfigInstance);
  const featuredProductService = new FeaturedProductService(apiConfigInstance);
  const shipmentTypeService = new ShipmentTypeService(apiConfigInstance);
  const configService = new ConfigService(apiConfigInstance);
  const userService = new UserService(apiConfigInstance);

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        // Fetch categories
        const categories = await categoryService.getCategories();
        setCategoriesState({ categories, loading: false, error: null });
    
        // Fetch products for each category in parallel
        const productsMap = new Map<string | number, ProductsState>();
        await Promise.all(
          categories.map(async (category) => {
            try {
              const products = await productService.getProducts(category.id);
              productsMap.set(category.id, { products, loading: false, error: null });
            } catch (productError) {
              productsMap.set(category.id, { products: [], loading: false, error: productError as AxiosError | Error });
            }
          })
        );
    
        setProductsLookup({ products: productsMap });
    
      } catch (error) {
        // Handle category fetching error
        setCategoriesState({ categories: [], loading: false, error: error as AxiosError | Error });
        setProductsLookup({ products: new Map() }); // Clear products lookup as well
      }
    };
    
    fetchCategoriesAndProducts();
  }, []);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const featuredProducts = await featuredProductService.getAllFeaturedProducts();
        setFeaturedProductsState({ featuredProductIds: new Set(featuredProducts.map(product => product.id)), loading: false, error: null });
      } catch (error) {
        setFeaturedProductsState(prevState => ({ ...prevState, loading: false, error: error as AxiosError | Error }));
      }
    };
    fetchFeaturedProducts();
  }, []);

  useEffect(() => {
    const fetchShipmentTypes = async () => {
      try {
        const shipmentTypes = await shipmentTypeService.getShipmentTypes();
        setShipmentTypesState({ shipmentTypes, loading: false, error: null });
      } catch (error) {
        setShipmentTypesState(prevState => ({ ...prevState, loading: false, error: error as AxiosError | Error }));
      }
    };

    fetchShipmentTypes();
  }, []);

  useEffect(() => {
    const getCarouselImages = async () => {
      try {
        const carouselImageService = new CarouselImageService(apiConfigInstance);
        const carouselImages = await carouselImageService.getCarouselImages();
        setCarouselImagesState({ loading: false, images: carouselImages, error: null });
      } catch (error) {
        setCarouselImagesState(prevState => ({ ...prevState, loading: false, error: error as AxiosError | Error }));
      }
    };

    getCarouselImages();
  }, []);

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const fetchedConfigs = await configService.extractAppDBStoredConfigs();
        setConfigsState({ configs: fetchedConfigs, loading: false, error: null });
      } catch (error) {
        setConfigsState({ configs: null, loading: false, error: error as AxiosError | Error });
      }
    };

    fetchConfigs();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await userService.getUsers();
        setUsersState({ users, loading: false, error: null });
      } catch (error) {
        setUsersState({ users: [], loading: false, error: error as AxiosError | Error });
      }
    };

    fetchUsers();
  }, []);

  const setProductsByCategory = (categoryId: string | number, products: ProductData[]) => {
    setProductsLookup(prevProductsLookup => {
      const updatedProductsMap = new Map(prevProductsLookup.products);
      updatedProductsMap.set(categoryId, { products, loading: false, error: null });
      return { products: updatedProductsMap };
    });
  };

  const updateProductByCategory = (categoryId: string | number, updatedProduct: ProductData) => {
    // Get the current list of products for the given category
    const currentProductsState = productsLookup.products.get(categoryId);
  
    if (currentProductsState) {
      // Find the index of the product to be updated
      const productIndex = currentProductsState.products.findIndex(
        (found) => found.id === updatedProduct.id
      );
  
      if (productIndex !== -1) {
        const updatedProducts = [...currentProductsState.products];
        updatedProducts[productIndex] = updatedProduct;
      
        // Update the products map with the new product list for this category
        const updatedProductsState = {
          ...currentProductsState,
          products: updatedProducts,
        };
      
        // Update the products lookup state
        setProductsLookup((prevState) => {
          const updatedMap = new Map(prevState.products);
          updatedMap.set(categoryId, updatedProductsState);
          return {
            ...prevState,
            products: updatedMap,
          };
        });
      } else {
        console.error(`Product with id ${updatedProduct.id} not found in category ${categoryId}.`);
      }
    } else {
      console.error(`Category with id ${categoryId} not found.`);
    }
  };

  const appendProductByCategory = (categoryId: string | number, newProduct: ProductData) => {
    // Get the current list of products for the given category
    const currentProductsState = productsLookup.products.get(categoryId);
  
    if (currentProductsState) {
      // Create a new products array with the new product appended
      const updatedProducts = [...currentProductsState.products, newProduct];
  
      // Update the products map with the new product list for this category
      const updatedProductsState = {
        ...currentProductsState,
        products: updatedProducts,
      };
  
      // Update the products lookup state
      setProductsLookup((prevState) => {
        const updatedMap = new Map(prevState.products);
        updatedMap.set(categoryId, updatedProductsState);
        return {
          ...prevState,
          products: updatedMap,
        };
      });
    } else {
      console.error(`Category with id ${categoryId} not found.`);
    }
  };

  const deleteProductByCategory = (categoryId: string | number, productId: string | number) => {
    // Get the current list of products for the given category
    const currentProductsState = productsLookup.products.get(categoryId);
  
    if (currentProductsState) {
      // Filter out the product to be deleted
      const updatedProducts = currentProductsState.products.filter(
        (product) => product.id !== productId
      );
  
      // Update the products map with the new product list for this category
      const updatedProductsState = {
        ...currentProductsState,
        products: updatedProducts,
      };
  
      // Update the products lookup state
      setProductsLookup((prevState) => {
        const updatedMap = new Map(prevState.products);
        updatedMap.set(categoryId, updatedProductsState);
        return {
          ...prevState,
          products: updatedMap,
        };
      });
    } else {
      console.error(`Category with id ${categoryId} not found.`);
    }
  };
  

  const addFeaturedProduct = async (productId: number) => {
    try {
      await featuredProductService.addToFeatured(productId);
      setFeaturedProductsState(prevState => ({
        ...prevState,
        featuredProductIds: new Set(prevState.featuredProductIds).add(productId),
        error: null
      }));
    } catch (error) {
      setFeaturedProductsState(prevState => ({ ...prevState, error: error as AxiosError | Error }));
    }
  };

  const removeFeaturedProduct = async (productId: number) => {
    try {
      await featuredProductService.removeFromFeatured(productId);
      setFeaturedProductsState(prevState => {
        const updatedFeaturedProductIds = new Set(prevState.featuredProductIds);
        updatedFeaturedProductIds.delete(productId);
        return {
          ...prevState,
          featuredProductIds: updatedFeaturedProductIds,
          error: null
        };
      });
    } catch (error) {
      setFeaturedProductsState(prevState => ({ ...prevState, error: error as AxiosError | Error }));
    }
  };

  const updateConfigs = async (updatedConfigs: Configs) => {
    try {
      const savedConfigs = await configService.updateAppDBStoredConfigs(updatedConfigs);
      setConfigsState({ configs: savedConfigs, loading: false, error: null });
    } catch (error) {
      setConfigsState({ configs: configsState.configs, loading: false, error: error as AxiosError | Error });
      throw error; // rethrow to allow handling in the component
    }
  };

  const addUser = async (userInput: UserInput) => {
    try {
      const newUser = await userService.createUser(userInput);
      setUsersState(prevState => ({
        ...prevState,
        users: [...prevState.users, newUser],
        error: null
      }));
    } catch (error) {
      setUsersState(prevState => ({ ...prevState, error: error as AxiosError | Error }));
      throw error; // rethrow to allow handling in the component
    }
  };

  const updateUser = async (id: number, userInput: UserInput) => {
    try {
      const updatedUser = await userService.updateUser(id, userInput);
      setUsersState(prevState => ({
        ...prevState,
        users: prevState.users.map(u => (u.id === id ? updatedUser : u)),
        error: null
      }));
    } catch (error) {
      setUsersState(prevState => ({ ...prevState, error: error as AxiosError | Error }));
      throw error; // rethrow to allow handling in the component
    }
  };

  const deleteUser = async (id: number) => {
    try {
      await userService.deleteUser(id);
      setUsersState(prevState => ({
        ...prevState,
        users: prevState.users.filter(user => user.id !== id),
        error: null
      }));
    } catch (error) {
      setUsersState(prevState => ({ ...prevState, error: error as AxiosError | Error }));
      throw error; // rethrow to allow handling in the component
    }
  };

  return (
    <AdminContext.Provider value={{
      categoriesState,
      setCategoriesState,
      productsLookup,
      setProductsLookup,
      setProductsByCategory,
      appendProductByCategory,
      deleteProductByCategory,
      updateProductByCategory,
      shipmentTypesState,
      featuredProductsState,
      addFeaturedProduct,
      removeFeaturedProduct,
      setShipmentTypesState,
      carouselImagesState,
      setCarouselImagesState,
      configsState,
      setConfigsState,
      updateConfigs,
      usersState,
      setUsersState,
      addUser,
      updateUser,
      deleteUser
    }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdminContext must be used within an AdminProvider');
  }
  return context;
};
