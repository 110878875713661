import React from "react";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Box, Paper, TableContainer } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from "../../../utils/modals/ConfirmationModal";
import { ShipmentType } from "../../../../models/shipmentType";

interface ShipmentsTypeTableProps {
    shipments: ShipmentType[];
    onShipmentUpdate: (shipment: ShipmentType) => void;
    onShipmentDelete: (shipment: ShipmentType) => void;
}

const ShipmentsTypeTable: React.FC<ShipmentsTypeTableProps> = ({ shipments, onShipmentUpdate, onShipmentDelete }) => {
    const [editedShipments, setEditedShipments] = React.useState<Partial<ShipmentType>[]>([]);
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [shipmentToDelete, setShipmentToDelete] = React.useState<ShipmentType | null>(null);

    const handleFieldChange = (index: number, field: keyof ShipmentType, value: string) => {
        const updatedCategories = [...editedShipments];
        updatedCategories[index] = {
            ...editedShipments[index],
            [field]: value
        };
        setEditedShipments(updatedCategories);
    };

    const handleBlur = (index: number) => {
        const editedShipment = editedShipments[index];
        if (editedShipment) {
            const shipmentToUpdate = {
                ...shipments[index],
                ...editedShipment
            };
            onShipmentUpdate(shipmentToUpdate);
        }
    };

    const handleDeleteConfirmation = () => {
        if (shipmentToDelete) {
            onShipmentDelete(shipmentToDelete);
            setShipmentToDelete(null);
            setShowDeleteModal(false);
        }
    };

    const handleDeleteCancel = () => {
        setShipmentToDelete(null);
        setShowDeleteModal(false);
    };

    return (
        <TableContainer component={Paper} elevation={3} sx={{ marginTop: 4, padding: 2, backgroundColor: "rgb(255,255,255, 0.8)" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Precio (€)</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shipments.map((shipment, index) => (
                        <TableRow key={shipment.id}>
                            <TableCell>
                                <TextField
                                    type="text"
                                    value={editedShipments[index]?.name || shipment.name}
                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                    onBlur={() => handleBlur(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    multiline
                                    type="text"
                                    value={editedShipments[index]?.description || shipment.description}
                                    onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                    onBlur={() => handleBlur(index)}
                                    fullWidth  // Ensures the text field takes up the full width of the cell
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="number"
                                    value={editedShipments[index]?.price || shipment.price}
                                    onChange={(e) => handleFieldChange(index, 'price', e.target.value)}
                                    onBlur={() => handleBlur(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    setShipmentToDelete(shipment);
                                    setShowDeleteModal(true);
                                }}><CloseIcon /></IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ConfirmationModal
                open={showDeleteModal}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirmation}
                title="Eliminar Categoría"
                content="Si continuas la categoría se eliminará."
                confirmText="Eliminar"
                cancelText="Cancelar"
            />
        </TableContainer>
    );
};

export default ShipmentsTypeTable;
